.location-block{
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 30px;
  .location_btn {
    background: transparent;
    color: #FFFFFF;
    font-size: 14px;
    line-height: 17px;
    cursor: pointer;
    display: inline-block;
    img{
      margin: 0 5px 2px;
    }
    &:hover{
      opacity: 0.7;
    }
  }
}

.location_popper_inner {
  .default_button_contained {
    width: 100%;
    height: 28px;
    line-height: 28px;
    font-size: 12px;
  }
  p {
    margin-top: 10px;
    color: #7F8FA4;
    font-size: 12px;
    font-weight: 300;
    line-height: 15px;
  }
}

.location_info_description {
  color: #334150;
  font-size: 16px;
  line-height: 20px;
  margin: 20px 0;
}
