.favorites_dialog_root{
  margin: auto !important;
  top: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  position: fixed !important;
}

.buy_error_dialog {
  z-index: 999999999999!important;
}

.paper_custom_dialog{
  max-width: 1200px !important;
  min-width: 840px;
}

.dialog_favorite_close_button{
  position: absolute;
  top: 20px;
  right: 20px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.dialog_favorite_close_button:hover{
  background: none !important;
}

.icon_close{
  width: 16px;
  height: 16px;
}
.favorites-page{
  .title{
    padding: 50px 0 30px 0;
    color: #334150;
    font-size: 60px;
    line-height: 75px;
  }
}

.favorites-all{
  padding-top: 15px;
   .all-list-pagination{
     margin-top: 20px;
   }
}

.favorites{
  margin-bottom: 54px;
  background: #fff;
  padding: 35px 50px 40px 50px;
  background-color: #FFFFFF;
}

.favorites-tabs{
  .preloader_wrapper{
    margin-top: 20px;
  }
  .tab-button{
    button{
      font-family: "Akrobat", sans-serif;
      margin-right: 30px;
      background: transparent;
      cursor: pointer;
      color: #7F8FA4;
      font-size: 16px;
      line-height: 20px;
      -webkit-transition: all 0.3s linear;
      -moz-transition: all 0.3s linear;
      -ms-transition: all 0.3s linear;
      -o-transition: all 0.3s linear;
      transition: all 0.3s linear;
    }
    .active{
      color: #334150;
      position: relative;
      &:before{
        content: "";
        bottom: -9px;
        left: 0;
        position: absolute;
        width: 100%;
        height: 2px;
        background: #0253B3;
      }
    }
  }
}

.tabs-block{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  .title-list-favorites.scrollbar.scrollbar-primary{
    padding-bottom: 20px;
  }

  .scrollbar {
    overflow-x: auto;
  }

  .scrollbar-primary::-webkit-scrollbar {
    height: 5px;
    background-color: #C2D1D9;
    border-radius: 15px;
  }

  .scrollbar-primary::-webkit-scrollbar-thumb {
    border-radius: 15px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #0253B3;
    opacity: 0.1;
  }
}

.favorites-table{
  .table_header .table_header{
    display: flex;
  }
  .table_header{
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    .table_row{
      height: 50px;
    }
    .row_item{
      color: #7F8FA4;
      font-size: 12px;
      font-weight: 300;
      line-height: 15px;
      .check_field_wrapper{
        margin: 0;
        label > :last-child{
          color: #7F8FA4;
          font-size: 12px;
          font-weight: 300;
          line-height: 15px;
          font-family: "Akrobat", sans-serif;
          margin-left: 10px;
        }
      }
    }
    .table_row{
      border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    }
    .table_row:last-child{
      border-bottom: none;
    }
  }

  .table_body{
    .row_item{
      color: #334150;
      font-size: 16px;
      line-height: 20px;
      .name{
        text-decoration: none;
      }
      .name:hover{
        text-decoration: underline;
      }
      .check_field_wrapper{
        margin: 0;
        label > :last-child{
          color: #334150;
          font-size: 16px;
          font-weight: 600;
          line-height: 15px;
          font-family: "Akrobat", sans-serif;
          margin-left: 10px;
        }
      }
    }
    .table_row{
      border-bottom: 2px solid rgba(0, 0, 0, 0.1);
      border-top: none;
    }
    .table_row:last-child{
      border-bottom: none;
    }
  }

  .table_row{
    width: 100%;
    display: flex;
    align-items: center;
    height: 60px;
  }

  .table_row {
    & > :first-child {
      width: 16%;
    }
    & > :nth-child(2) {
      width: 16%;
      a{
        color: #0253B3;
        font-size: 16px;
        line-height: 20px;
      }
    }
    & > :nth-child(3) {
      width: 16%;
      span{
        color: #334150;
        font-size: 16px;
        line-height: 20px;
      }
    }
    & > :nth-child(4) {
      width: 16%;
      span{
        color: #334150;
        font-size: 16px;
        line-height: 20px;
      }
    }
    & > :nth-child(5) {
      width: 21%;
      span{
        color: #334150;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        flex-wrap: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
    & > :last-child {
      width: 15%;

    }
  }
}

.empty-favorites-list{
  margin-top: 20px;
  height: 120px;
  width: 100%;
  background-color: #FFFFFF;
  box-shadow: 0 2px 8px 0 rgba(0,0,0,0.15);
  padding: 40px 50px;
  p{
    height: 40px;
    width: 759px;
    color: #7F8FA4;
    font-family: Akrobat;
    font-size: 16px;
    line-height: 20px;}
}

.favorites-table-button{
  display: flex;
  margin: 0 0 0 20px;
  justify-content: flex-end;
  button{
    background: transparent;
    cursor: pointer;
    font-size: 16px;
    line-height: 20px;
    text-align: right;
    font-family: "Akrobat", sans-serif;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
    &:hover{
      opacity: 0.7;
    }
  }
  .btn-blue{
    color: #0253B3;
    margin-right: 20px;
  }
  .btn-red{
    color: #D0021B;
  }
}

.favorites-form-button{
  display: flex;
  padding-top: 30px;
  border-top: 2px solid rgba(0, 0, 0, 0.1);
  .default_button_wrapper{
    button{
      margin: 0 14px 0 0;
    }
  }
}

.favorites-items{
  padding: 30px;
  border: 1px solid #C2D1D9;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .catalog-info-items{
    a{
      color: #0253B3;
      font-size: 16px;
      line-height: 20px;
      cursor: pointer;
      text-decoration: none;
      -webkit-transition: all 0.3s linear;
      -moz-transition: all 0.3s linear;
      -ms-transition: all 0.3s linear;
      -o-transition: all 0.3s linear;
      transition: all 0.3s linear;
      &:hover{
        opacity: 0.7;
      }
    }
  }
  .info{
    color: #7F8FA4;
    font-size: 16px;
    line-height: 20px;
  }
  .name-items{
    display: flex;
    color: #7F8FA4;
    font-size: 23px;
    div{
      color: #334150;
      font-family: "Akrobat SemiBold", sans-serif;
    }
    p{
      padding: 0 10px;
      font-family: "Akrobat Light", sans-serif;
    }
  }
}
.dialog-delete-wrapper{
  .title{
    color: #334150;
    font-size: 32px;
    line-height: 40px;
    padding-bottom: 30px;
  }
  .descriptions{
    color: #334150;
    font-size: 16px;
    font-family: Akrobat;
    line-height: 20px;
  }
}
.dialog-button-wrapper{
  border-top: 1px solid #C2D1D9;
  padding-top: 30px;
  margin-top: 28px;
  display: flex;
  button{
    margin-right: 15px;
  }
  .error-text{
    margin: auto 10px;
    color: #D0021B;
  }
  &.margin_top_10 {
    margin-top: 10px!important;
  }
}

.favorites-dialog{
  .title{
    color: #334150;
    font-size: 32px;
    line-height: 40px;
    padding-bottom: 20px;
  }
  .name-items{
    padding: 30px;
    display: flex;
    flex-flow: column;
    border: 1px solid #C2D1D9;
    border-radius: 5px;
    margin-bottom: 40px;
    span{
      color: #334150;
      font-size: 23px;
      font-weight: 600;
      line-height: 29px;
    }
    p{
      color: #7F8FA4;
      font-size: 16px;
      line-height: 20px;
    }
  }
  .dialog-field-change{
    flex-flow: row;
    justify-content: space-between;
    input{
      font-size: 14px;
      color: #334150;
      &::placeholder{
        color: #7F8FA4;
      }
    }
    .custom_input_wrapper:last-of-type{
      position: relative;
      &:before{
        position: absolute;
        font-family: "Akrobat", sans-serif;
        content: "KM";
        right: 12px;
        z-index: 11;
        color: #7F8FA4;
        font-size: 14px;
        font-weight: 300;
        line-height: 18px;
        top: calc(50% - 9px);
      }
    }
  }
  .frame-number{
    .frame-text{
      padding-bottom: 40px;
      color: #334150;
      font-size: 14px;
      line-height: 17px;
    }
    .custom_input_wrapper{
      &:before{
        display: none;
      }
    }
  }

  .block-info{
    .descriptions{
      padding-bottom: 30px;
      font-size: 12px;
      color: #7F8FA4;
      //line-height: 17px;
    }
    input{
      box-sizing: border-box;
      margin-right: 49px;
      height: 59px;
      width: 651px;
      border: 1px solid #C2D1D9;
      border-radius: 3px;
      padding: 13px 11px;
      font-size: 14px;
      font-weight: 300;
      line-height: 18px;
      //resize: none;
      color: #334150;
      font-family: "Akrobat", sans-serif;
    }
    .favorites-button{
      padding-bottom: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      button{
        position: relative;
        display: flex;
        flex-flow: column;
        justify-content: flex-end;
        width: 310px;
        height: 172px;
        padding: 17px 25px;
        cursor: pointer;
        -webkit-transition: all 0.3s linear;
        -moz-transition: all 0.3s linear;
        -ms-transition: all 0.3s linear;
        -o-transition: all 0.3s linear;
        transition: all 0.3s linear;
        &:hover{
          opacity: 0.8;
        }
        p{
          color: #FFFFFF;
          font-size: 19.81px;
          font-weight: 600;
          line-height: 25px;
        }
        span{
          color: #7F8FA4;
          font-size: 13.78px;
          //line-height: 17px;
        }
      }
      button:first-of-type{
        background: url("../../assets/image/Group 9.png") no-repeat;
      }
      button:last-of-type{
        background: url("../../assets/image/Group 8.png") no-repeat;
      }
      .active{
        &:before{
          content: "";
          position: absolute;
          top: 19px;
          right: 19px;
          background: url("../../assets/image/Path.png") no-repeat;
          width: 21px;
          height: 15px;
        }
      }
    }
    .label-block {
      display: flex;
      flex-flow: column;
      color: #7F8FA4;
      font-size: 14px;
      font-weight: 300;
      line-height: 18px;
      width: 301px;
    }
    .textarea-block{
      & > div{
        margin-top: 2px;
      }
      textarea {
        height: 60px;
        border: 1px solid #c2d1d9;
        border-radius: 3px;
        margin-bottom: 35px;
        overflow: hidden;
        padding: 10px;
        margin-top: 5px;
        font-size: 14px;
        font-weight: 400;
        resize: none;
        overflow-y: auto;
        &::placeholder {
          font-size: 14px;
          color: #7F8FA4;
        }
      }
      input{
          box-sizing: border-box;
          margin-right: 49px;
          height: 41px;
          width: 301px;
          border: 1px solid #C2D1D9;
          border-radius: 3px;
          padding: 13px 11px;
          font-size: 14px;
          font-weight: 300;
          line-height: 18px;
          resize: none;
          color: #334150;
          font-family: "Akrobat", sans-serif;
      }
      input::placeholder{
        word-spacing: 220px;
        color: #7F8FA4;
        font-family: Akrobat;
        font-size: 14px;
        line-height: 18px;
      }
      & > span {
        font-size: 12px;
        color: #7F8FA4;

      }
    }
    .textarea-comment{
      textarea{
        box-sizing: border-box;
        margin-right: 49px;
        height: 59px;
        width: 651px;
        border: 1px solid #C2D1D9;
        border-radius: 3px;
        padding: 13px 11px;
        font-size: 14px;
        font-weight: 300;
        line-height: 18px;
        resize: none;
        color: #334150;
        font-family: "Akrobat", sans-serif;
        &::placeholder {
          font-size: 14px;
          color: #7F8FA4;
        }
      }


    }
    .textarea-block-update{
      display: flex;
      .name-update-list-favorites{
        display: flex;
        flex-flow: column;
        span{
          color: #7F8FA4;	font-family: Akrobat;	font-size: 14px;	font-weight: 300;	line-height: 18px;
          margin-bottom: 4px;
        }
        input{
          width: 301px;
          border: 1px solid #C2D1D9;
          border-radius: 3px;
          height: 41px;
        }
        input:placeholder{
            color: #334150;
            font-family: Akrobat;
            font-size: 14px;
            line-height: 18px;
        }
        input[type=text] {
            color: #334150;
            font-family: Akrobat;
            font-size: 14px;
            line-height: 18px;
        }

      }
      .mileage-update-list-favorites{
        display: flex;
        flex-flow: column;
        justify-content: flex-end;
        span{
          color: #7F8FA4;	font-family: Akrobat;	font-size: 14px;	font-weight: 300;	line-height: 18px;
          margin-bottom: 4px;
        }
        .input-mileage{
          display: flex;
          flex-flow: row;
          align-items: center;
          height: 41px;
          width: 301px;
          border: 1px solid #C2D1D9;
          border-radius: 3px;
          input{
            border: none;
            height: 39px;
            width: 270px;
            margin: 0;
            margin-right: 5px;
          }
          input:-internal-autofill-selected{
            background-color: none;
          }
          input:placeholder{
            	color: #334150;
              font-family: Akrobat;
              font-size: 14px;
              line-height: 18px;
              padding: 3px;
          }
          input[type=number] {
              color: #334150;	font-family: Akrobat;	font-size: 14px;	line-height: 18px;
          }
        }
      }
    }
  }
}
.table_body_starting_search {
  &>.table_row {
    cursor: pointer;
    &:hover {
      background-color: #EDF2F8;
    }
  }
}

.title-list-favorites{
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  margin-right: 50px;
  span{
    flex: 0 0 auto;
  }
}
