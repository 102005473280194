.loader_wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  & > div {
    color: #01397C;
  }
}
.loader-in-container-wrapper {
  display: flex;
  align-items: center;
  position: relative;
  height: 70vh;
  &>div {
    position: absolute;
    width: 100px;
    left: calc(50% - 50px);
  }
}