.title-block{
  .app-back_link{
    margin: 50px 0;
  }
  & > h1{
    margin: 0;
  }

}
.customer-page{
  margin-top: 30px;
  padding: 40px 50px;
    .reviews-wrapper{
      .commends{
        color: #0253B3;
        font-family: sans-serif Akrobat;
        font-size: 14px;
        font-weight: 300;
      }
    }
  .title-supplier{
      & > p {
        margin-bottom: 0;
      }
  }
  & > .expansion-block{
    & > .expansion-body{
      margin-top: 20px;
    }
  }

}


.review_title{
  color: #01397C;
  font-family: sans-serif Akrobat;
  font-size: 23px;
  font-weight: 600;
}

.reviews{
  margin-bottom: 60px;
}
