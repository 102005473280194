.table-offers{
  position: relative;
  background: #fff;
  width: 100%;
  padding: 20px 30px 10px 30px;
  .not-offers{
    width: 220px;
    h1{
      height: 40px;
      width: 215px;
      color: #334150;
      font-family: Akrobat;
      font-size: 32px;
      line-height: 40px;
      font-weight: 300;
    }
  }
  .special-block{
    position: absolute;
    margin: 10px 0 0px;
    right: 30px;
    z-index: 10;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 40px;
    .setting-block{
      display: flex;
      flex-flow: column;
      justify-content: center;
      width: 150px;
      position: relative;
      margin-left: 25px;
      .li-option{
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        width: 100%;
        height: 40px;
        border: 1px solid #C2D1D9;
        border-radius:  5px;
        margin: auto;
        padding: 10px;
        .name-option{
          margin: 0 10px;
        }
      }
      .dropdown-options{
        position: absolute;
        top: 45px;
        width: 150px;
        z-index: 10;
        background: white;
        border: 1px solid #C2D1D9;
        border-radius: 5px;
        li{
          display: flex;
          flex-flow: row;
          align-items: center;
          justify-content: flex-start;
          cursor: pointer;
          width: 100%;
          height: 40px;
          padding: 10px;
          -webkit-transition: all 0.3s linear;
          -moz-transition: all 0.3s linear;
          -ms-transition: all 0.3s linear;
          -o-transition: all 0.3s linear;
          transition: all 0.3s linear;
          &:hover{
            background-color: #EDF2F8;
          }
          .name-option{
            margin: 0 10px;
          }
        }
        .active{
            background-color: #EDF2F8;
        }
      }
    }
    .control-length-radius{
      color: #7F8FA4;
      height: 18px;
      width: 150px;
      font-size: 14px;
      font-weight: 300;
      line-height: 18px;
      .length-radius{
        margin: 0 5px;
        text-decoration: underline;
        color: #0253B3;
        font-size: 14px;
        cursor: pointer;
        -webkit-transition: all 0.3s linear;
        -moz-transition: all 0.3s linear;
        -ms-transition: all 0.3s linear;
        -o-transition: all 0.3s linear;
        transition: all 0.3s linear;
        &:hover{
          text-decoration: none;
        }
      }
    }
  }
  .block-list-offer{
    position: relative;
    margin-bottom: 10px;
  }
  .title{
    font-size: 32px;
    margin: 10px 0 20px;
    color: #334150;
  }
  p{
    color: #7F8FA4;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 30px;
  }
  .name{
    align-items: center;
    color: #7F8FA4;
    font-size: 23px;
    line-height: 29px;
    display: flex;
    a{
      color: #01397c;
      font-weight: 600;
      -webkit-transition: all 0.3s linear;
      -moz-transition: all 0.3s linear;
      -ms-transition: all 0.3s linear;
      -o-transition: all 0.3s linear;
      transition: all 0.3s linear;
      text-decoration: none;
      &:hover{
        text-decoration: underline;
      }
    }
    p{
      padding: 0 13px;
      margin: 0;
    }
    .favourites_btn{
      background: url("../../../assets/image/favourites2.png");
      width: 19px;
      height: 18px;
      -webkit-transition: all 0.3s linear;
      -moz-transition: all 0.3s linear;
      -ms-transition: all 0.3s linear;
      -o-transition: all 0.3s linear;
      transition: all 0.3s linear;
      &:hover{
        background: url("../../../assets/image/favourites.png");
      }
    }
    .favourites_chosen_btn{
      width: 19px;
      height: 18px;
      -webkit-transition: all 0.3s linear;
      -moz-transition: all 0.3s linear;
      -ms-transition: all 0.3s linear;
      -o-transition: all 0.3s linear;
      transition: all 0.3s linear;
      background: url("../../../assets/image/favourites.png");
    }
    button{
      margin-left: 20px;
      background: transparent;
      cursor: pointer;
    }
  }
}

.modal-container.confirm{
  padding: 0;
  .main-block.radius{
    height: 200px;
    width: 100%;
    padding-bottom: 20px;
    margin-bottom: 30px;
    border-bottom: 1px solid #C2D1D9;
  }
  .list-button{
    display: flex;
    flex-flow: row wrap;
    margin: 0;
    *{
      margin: 0;
      padding: 0;
    }
    div:first-child{
      margin-right: 15px;
    }
  }
}

.modal-container{
  padding: 0;
  .close{
    position: absolute;
    top: 20px;
    right: 20px;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
    &:hover{
      opacity: 0.7;
    }
    img{
      height: 15px;
      width: 15px;
    }
  }
  .main-block{
    width: 100%;
    h1{
      color: #334150;
      font-family: Akrobat;
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 30px;
      font-weight: 500;
    }
    span{
      color: #334150;
      font-family: Akrobat;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 15px;
    }
    .state-new-distance{
      span{
        color: #7F8FA4;
        font-family: Akrobat;
        font-size: 12px;
        font-weight: 300;
        line-height: 18px;
      }
      div{
        display: flex;
        flex-flow: row;
        align-items: center;
        height: 41px;
        width: 95px;
        border: 1px solid #C2D1D9;
        border-radius: 3px;
        input{
          color: #334150;
          font-family: Akrobat;
          font-size: 14px;
          line-height: 18px;
          padding-left: 12px;
          width: 70px;
        }
        input[type=number] {
            -moz-appearance:textfield;
        }
        span{
          color: #7F8FA4;
          font-family: Akrobat;
          font-size: 14px;
          font-weight: 300;
          line-height: 18px;
          text-align: right;
          margin: 0;
          padding-right: 12px;
        }
      }
    }
  }
}

.table{
  margin-bottom: 30px;
  .table-row{
    display: flex;
    align-items: center;
    padding: 15px 0;
    border-bottom: 2px solid rgba(0, 0, 0,0.1);
    >div:first-of-type{
      width: 21%;
    }
    >div:nth-child(2){
      width: 16%;
    }
    >div:nth-child(3){
      width: 16%;
    }
    >div:nth-child(4){
      width: 16%;
    }
    >div:nth-child(5){
      width: 13%;
    }
    >div:nth-child(6){
      width: 17%;
      text-align: right;
    }
    .product{
      .name{
        color: #0253B3;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        -webkit-transition: all 0.3s linear;
        -moz-transition: all 0.3s linear;
        -ms-transition: all 0.3s linear;
        -o-transition: all 0.3s linear;
        transition: all 0.3s linear;
        &:hover{
          text-decoration: none;
        }
      }
      .reviews-wrapper{
        display: flex;
        flex-flow: row;
        justify-content: flex-start;
        align-items: flex-end;
        padding: 0;
        .review{
          margin: 0 5px;
          color: #7F8FA4;
          font-family: Akrobat;
          font-size: 12px;
          font-weight: 300;
        }
      }
    }
    .time{
      color: rgba(0, 0, 0,0.5);
      font-size: 12px;
      font-weight: 300;
      line-height: 15px;
      align-self: flex-end;
      img{
        margin-right: 5px;
      }
    }
    .availability{
      display: flex;
      flex-flow: column;
      span:first-of-type{
        font-size: 12px;
        color: #7F8FA4;
        font-weight: 300;
        font-family: Akrobat;
      }
      span{
        color: #334150;
        font-size: 14px;
      }
    }
    .distance{
      display: flex;
      flex-flow: column;
      span:first-of-type{
        	color: #7F8FA4;
          font-family: Akrobat;
          font-size: 12px;
          font-weight: 300;
          line-height: 17px;
      }
      span{
        color: #334150;
        font-family:
        Akrobat;
        font-size: 14px;
      }
    }
    .delivery-time{
      display: flex;
      flex-flow: column;
      span:first-of-type{
        color: #7F8FA4;
        font-size: 12px;
        text-decoration: none;
      }
      span{
        color: #334150;
        font-size: 14px;
        text-decoration: underline;
        -webkit-transition: all 0.3s linear;
        -moz-transition: all 0.3s linear;
        -ms-transition: all 0.3s linear;
        -o-transition: all 0.3s linear;
        transition: all 0.3s linear;
        &:hover{
          text-decoration: none;
          cursor: pointer !important;
        }
      }
    }
    .price{
      color: #334150;
      font-size: 23px;
      line-height: 27px;
      font-weight: 600;
      text-align: center !important;
      span{
        font-weight: 500;
      }
    }

  }
}

.all-product{
  color: #0253B3;
  font-size: 16px;
  line-height: 20px;
  font-family: Akrobat;
  cursor: pointer;
  margin: 20px 0 0px;
  background: transparent;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  span{
    font-size: 10px;
    vertical-align: bottom;
  }
  &:hover{
    opacity: 0.7;
  }
}
