.contacts{
  margin-bottom: 20px;
  .block_label{
    padding-bottom: 7px;
    color: #7F8FA4;
    font-size: 12px;
    font-weight: 300;
    line-height: 18px;
  }
  .contacts-info{
    align-items: center;
    display: flex;
    padding-bottom: 5px;
    .info{
      color: #334150;
      font-size: 23px;
      line-height: 29px;
      padding-right: 20px;
    }
    .info-social{
      img{
        margin-right: 8px;
      }
    }
  }
}