.phones {
  .phones-label {
    margin-bottom: 7px;
    color: #7F8FA4;
    font-size: 12px;
    font-weight: 300;
    line-height: 25px;
  }
  .contacts{
    margin: 5px 0;
    .contacts-info{
      align-items: center;
      display: flex;
      padding-bottom: 5px;
      .info{
        color: #334150;
        font-size: 23px;
        line-height: 29px;
        padding-right: 20px;
      }
      .info-social{
        img{
          margin-right: 8px;
        }
      }
    }
  }
}