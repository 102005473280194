.contact_details_wrapper{
  background: #FFF;
  padding: 40px 50px;
  margin: 30px 0 44px 0;
  display: flex;
  flex-flow: column;
  .title{
    padding-bottom: 25px;
    color: #334150;
    font-size: 16px;
    line-height: 20px;
  }
  .contact_details_form{
    .descriptions{
      color: #7F8FA4;
      font-size: 12px;
      font-weight: 300;
      line-height: 18px;
      display: flex;
      padding-bottom: 3px;
    }
    .block_field{
      max-width: 650px;
      justify-content: space-between;
      display: flex;
      padding-bottom: 14px;
    }
    input{
      /*font-family: "Akrobat", sans-serif;
      box-sizing: border-box;
      height: 41px;
      width: 301px;
      border: 1px solid #C2D1D9;
      border-radius: 3px;
      padding: 12px;
      color: #334150;
      font-size: 14px;
      line-height: 18px;
      &::placeholder{
        color: #7F8FA4;
      }*/
    }
    textarea{
      font-family: "Akrobat", sans-serif;
      height: 79px;
      width: 650px;
      box-sizing: border-box;
      resize: none;
      border: 1px solid #C2D1D9;
      border-radius: 3px;
      padding: 12px;
      color: #334150;
      font-size: 14px;
      line-height: 18px;
      &::placeholder{
        color: #C2D1D9;
      }

    }
    .btn-wrapper{
      border-top: 1px solid #C2D1D9;
      padding-top: 30px;
      margin-top: 30px;
    }

  }
}
