* {
  box-sizing: border-box;
}

body {
  background-color: #EDF2F8;
  color: #334150;
  //font-family: "Akrobat Regular", sans-serif;
  font-family: "Akrobat", sans-serif;

}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance:textfield;
}

.title-wrapper{
  color: #334150;
  font-size: 32px;
  line-height: 40px;
}

.container {
  width: 100%;
  padding: 0 150px;
}
.container-profile {
  margin-top: 50px;
}
.flex-center {
  display: flex;
  align-items: center;
}

.flex-center-btw {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flex-center-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.date_wrapper {
  color: #7F8FA4;
  font-size: 12px;
  font-weight: 300;
  line-height: 15px;
  display: flex;
  align-items: center;
  img {
    margin-right: 5px;
  }
}


div {
  .add_btn {
    color: #0253B3;
    font-family: "Akrobat", sans-serif;
    font-size: 16px;
    line-height: 20px;
    text-align: right;
    text-transform: none;
    margin-right: -5px;
    padding: 0;
    img {
      margin-left: 9px;
      -webkit-transition: all 0.3s linear;
      -moz-transition: all 0.3s linear;
      -ms-transition: all 0.3s linear;
      -o-transition: all 0.3s linear;
      transition: all 0.3s linear;
    }
    &:hover{
      background: none;
      text-decoration: underline;
      img {
        transform: rotate(180deg);
      }
    }
  }
}


.table_container{
  .table_header .table_header{
    display: flex;
  }
}

.table_header{
  .row_item{
    color: #7F8FA4;
    font-size: 12px;
  }
}

.table_body{
  .row_item{
    color: #334150;
    font-size: 16px;
  }
  .table_row{
    border-top: 2px solid rgba(0, 0, 0, 0.1);

  }

}

.table_row{
  width: 100%;
  display: flex;
  align-items: center;
  height: 60px;
}

.app-back_link {
  margin: 48px 0;
  display: inline-block;
  cursor: pointer;
}


.title-block{
  h1 {
    color: #334150;
    font-size: 60px;
    line-height: 75px;
    margin: 30px 0;
    font-weight: 400;
  }
}

.preloader_full_wrapper {
  height: 100vh;
}

.no-reviews {
  text-align: center;
  padding-bottom: 50px;
  .box-img {
    margin-bottom: 20px;
  }
}