.dashboard-footer{
  width: 100%;
  background-color: #EDF2F8;
  .footer{
    border-bottom: 1px solid rgba(127, 143, 164, 0.5);
    padding: 30px 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .footer-logo{
      position: absolute;
      top: 30px;
      left: 0;
    }
    .nav-info{
      a{
        text-decoration: none;
        -webkit-transition: all 0.3s linear;
        transition: all 0.3s linear;
        cursor: pointer;
        opacity: 0.5;
        color: #334150;
        font-size: 16px;
        line-height: 20px;
        margin: 0 25px;
        &:hover{
          opacity: 1;
        }
      }
      .active{
        opacity: 1;
      }
    }
  }
  .info{
    color: #7F8FA4;
    font-size: 12px;
    font-weight: 300;
    line-height: 15px;
    padding: 10px 0 18px;
  }
}