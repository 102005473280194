.Collapsible, .leaf-style {
   .Collapsible, .leaf-style {
     margin-left: 27px;
   }
 }
.leaf-style__trigger{
  display: block;
  font-weight: 400;
  text-decoration: none;
  position: relative;
  padding: 5px;
  margin-left: 10px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
}
.Collapsible__trigger {
  display: block;
  font-weight: 400;
  text-decoration: none;
  position: relative;
  padding: 5px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
  &.is-open {
    font-weight: 600;
    text-decoration: underline;
    &:before {
      border-style: solid;
      border-width: 0.05em 0.05em 0 0;
      content: '';
      display: inline-block;
      height: 0.2em;
      left: 0.15em;
      top: 0.15em;
      transform: rotate(-45deg);
      vertical-align: middle;
      width: 0.2em;
      margin-right: 5px;
    }
  }
  &.is-closed {
    font-weight: 400;
    text-decoration: none;
    &:before {
      border-style: solid;
      border-width: 0.05em 0.05em 0 0;
      content: '';
      display: inline-block;
      height: 0.2em;
      left: 0.15em;
      top: 0.15em;
      transform: rotate(135deg);
      vertical-align: middle;
      width: 0.2em;
      margin-right: 5px;
    }
  }
  &.is-disabled {
    color: #C2D1D9;
    &:before {
      display: none;
    }
    &:hover {
      opacity: 1;
      cursor: auto;
    }
  }
}