.show-map_dialog_root{
  z-index: 99999 !important;
}
.show-map_dialog_root .paper_custom_dialog{
  position: relative;
  height: 760px;
  width: 1240px;
}

.show-map{
  ymaps{
    >ymaps{
      >ymaps{
        >ymaps{
          >ymaps{
            >ymaps{
              .ymaps-2-1-74-balloon_to_top{
                top: -197px!important;
                left: -29px!important;
              }
            }
          }
        }
      }
    }
  }


}

.show-map{
  display: -webkit-moz-flex;
  display: flex;
  flex-flow: column;
  flex: 0 0 auto;
  justify-content: center;
  align-items: flex-start;
  padding-top: 60px;
  .loader_wrapper{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    align-content: center;
    overflow: auto;
  }
  .close{
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 10;
  }
  h2{
    position: absolute;
    top: 20px;
    left: 50px;
    font-weight: 500;
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: "Akrobat";
    color: #334150;
    font-size: 32px;
    line-height: 40px;
  }
}

.star-ratings-css{
  unicode-bidi: bidi-override;
  color: #c5c5c5;
  font-size: 16px;
  position: relative;
  padding: 0;
  text-shadow: 0px 1px 0 #a2a2a2;
  height: 15px;
  margin-left: 10px;
}
.star-ratings-css-top {
  color: #e7711b;
  padding: 0;
  position: absolute;
  z-index: 1;
  display: block;
  top: 0;
  left: 0;
  overflow: hidden;
}

/*
.ymaps-2-1-74-b-cluster-tabs__section .ymaps-2-1-74-b-cluster-tabs__section_type_nav{
  width: 125px !important;
  padding: 20px 30px !important;
  color: #7F8FA4 !important;
  font-family: Akrobat !important;
  font-size: 12px !important;
  font-weight: 300 !important;
  line-height: 15px !important;
}
*/


ymaps.ymaps-2-1-74-b-cluster-tabs {
  width: 514px !important;
}
.ymaps-2-1-74-b-cluster-tabs__menu{
  box-sizing: border-box;
  color: #7F8FA4;
  font-family: Akrobat;
  font-size: 12px;
  font-weight: 300;
  line-height: 15px;
  width: 125px;
  //height: auto !important;
}

.ymaps-2-1-74-b-cluster-tabs__section_type_nav{

}
.ymaps-2-1-74-balloon__content{
  padding: 20px 30px !important;
  height: 154px !important;
}

.ymaps-2-1-74-b-cluster-tabs__menu-item-text{
  //color: #7F8FA4 !important;
}


.ymaps-2-1-74-b-cluster-tabs__section .ymaps-2-1-74-b-cluster-tabs__section_type_nav{
  height: 154px !important;
}
.ymaps-2-1-74-balloon__content{

}

.ymaps-2-1-74-balloon__content > ymaps{
  width: 514px !important;
}



.wrapper_baloon{
  width: 1100px;
  height: 620px;
  .ymaps-2-1-75-balloon__content{
    //width: 496px;
    width: calc(100% - 20px) !important;
    min-height: 134px !important;
    margin: 0 !important;
    padding: 13px 0 !important;
    *{
      height: auto !important;
    }
    & > ymaps:first-child{
      //width: 540px !important;
      width: 100% !important;
      //border: 1px solid red;
    }
    .ymaps-2-1-75-b-cluster-content{
      width: 100% !important;
    }
  }
  .render_body{
    display: flex;
    flex-flow: column wrap;
    justify-content: space-around;
    //width: 319px !important;
    width: calc(100% - 55px) !important;
    height: 139px !important;
    //background: lightgray !important;
    margin-left: 30px !important;
  }
  .ymaps-2-1-75-b-cluster-tabs__menu{
    .ymaps-2-1-75-b-cluster-tabs__menu-item{
      background: none;
      .ymaps-2-1-75-b-cluster-tabs__menu-item-text{
        color: #7F8FA4;
        height: 15px;
        font-family: Akrobat;
        font-size: 12px;
        font-weight: 600;
        line-height: 15px;
      }
      &.ymaps-2-1-75-b-cluster-tabs__menu-item_current_yes{
        .ymaps-2-1-75-b-cluster-tabs__menu-item-text{
          color: #0253B3;
        }
      }
    }
  }
  .ymaps-2-1-75-balloon__layout{
    //border: 1px solid red;
    width: 575px !important;
    overflow: inherit !important;
  }
  .ymaps-2-1-75-b-cluster-tabs__section_type_content{
    padding: 0 !important;
    margin: 0 !important;
  }
  .ymaps-2-1-75-b-cluster-tabs__section_type_nav{
    //background: lightgray !important;
    max-width: 125px !important;
    height: 139px !important;
    border-right: 1px solid #7F8FA4;
    width: auto !important;
    padding: 0 30px !important;
    overflow-y: auto;
  }
}