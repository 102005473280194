.reviews{
  padding: 30px 50px 30px 50px;
  background: #fff;
  .title-block{
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
  }
  .title-wrapper{
    color: #334150;
    font-size: 32px;
    line-height: 40px;
  }
  .review_title{
    font-size: 23px;
  }
}

.review-item{
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  .time-block{
    padding-bottom: 15px;
    p{
      color: #7F8FA4;
      font-size: 12px;
      font-weight: 300;
      line-height: 15px;
    }
    img{
      margin-right: 5px;
    }
  }
  .review_title{
    h5{
      color: #01397C;
      font-size: 23px;
    }
  }
  .review_content{
    //color: #334150;
    font-size: 14px;
    line-height: 17px;
    padding: 10px 0 15px;
    word-break: break-all;
    width: 650px;
  }
  .descriptions{
    color: #334150;
    font-size: 14px;
    line-height: 17px;
    display: block;
    width: 650px;
    p{
      font-weight: 600;
      padding-right: 3px;
      white-space: nowrap;
      display: inline;
    }
    span{
      word-break: break-all;
      padding-left: 2px;
    }
  }
}

.container-rating{
  padding-top: 10px;
  display: flex;
  &>span{
    line-height: 40px;
    padding-left: 10px;
  }
}

.react-stars-wrapper{
  height: 40px;
  &>span{
    padding: 10px 0;
  }
}

.review_dialog{
  width: 840px !important;
  textarea {
    width: 651px;
    font-family: "Akrobat", sans-serif;
    margin-bottom: 0 !important;
  }
}

