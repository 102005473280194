.rating_wrapper {
  display: flex;
  align-items: center;
  line-height: 15px;
  & > span {
    margin-left: 8px;
    color: #7F8FA4;
    font-size: 14px;
    line-height: 17px;
  }
}
