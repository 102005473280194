div {
  .custom_badge {
    background-color: #249AF3;
    &.notification_badge {
      top: 15px;
      right: 15px;
      border: 1px solid #01397C;
      height: 8px;
      min-width: 8px;
    }
  }
}
