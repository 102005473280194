.schedule{
  margin-right: 30px;
  .block_label {
    margin-bottom: 15px;
    display: block;
  }
}

.schedule-time {
  margin-bottom: 15px;
}

.map_wrapper {
  min-width: 675px;
  height: 426px;
  position: relative;
  overflow: hidden;
}

.schedule_map_wrapper {
  width: 675px;
  height: 280px;
  position: relative;
  overflow: hidden;
}

.expansion-title {
  & > p {
    font-size: 23px;
    cursor: pointer;
    margin-right: 30px;
    margin-bottom: 20px;
    button {
      padding: 5px;
      svg {
        font-size: 18px;
      }
    }
  }
}

.time_day {
  color: #334150;
  font-family: "Akrobat SemiBold", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  margin-right: 15px;
  width: 15px;
  display: inline-block;
}

.time_value {
  color: #334150;
  font-size: 14px;
  line-height: 17px;
  width: 15px;
}

.expansion-block {
  //margin-top: 15px;
  .block_label{
    color: #7F8FA4;
    font-size: 12px;
    font-weight: 300;
    line-height: 18px;
  }
}

.expansion-block-D {
  margin-top: 15px;
  position: relative;
  overflow: hidden;
  height: 40px;
   .block_label{
    color: #7F8FA4;
    font-size: 12px;
    font-weight: 300;
    line-height: 18px;
  }
  .block-label-D{
    margin-bottom: 10px;
    color: #7F8FA4;
    font-size: 12px;
    font-weight: 300;
    line-height: 18px;
  }
}
.expnasion-block-D{
  position: relative;
  overflow: hidden;
  height: 67px;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.expansion-block-D-active{
  height: 351px;
  .expansion-body{
  }
}

.schedule-time_inactive {
  .time_day, .time_value {
    color: #7F8FA4;
  }
}

.info_section_no_items {
  border-bottom: 1px solid #C2D1D9;
  padding: 20px 0 30px;
  margin-bottom: 30px;
}

.info_section_loader {
  padding: 40px 0;
}

.info_company_loader_wrapper {
  height: 324px;
}
.loader_wrapper_center {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 50vh;
  &.loader_wrapper_center_dashboard {
    height: 300px;
  }
}
