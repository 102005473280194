.customer-page{
  padding: 30px 50px 40px 50px;
  background: #ffffff;
  margin-bottom: 30px;
  .flex-center-btw{
    justify-content: flex-start;
    .date_wrapper{
      margin-right: 30px;
    }
  }
  .btn-wrapper{
    padding-top: 30px;
  }
  .contacts{
    margin: 0;
  }
}
.reviews-wrapper{
  padding-bottom: 10px;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  p{
    margin-bottom: 10px;
    color: #7F8FA4;
    font-size: 12px;
    font-weight: 300;
    line-height: 18px;
  }
  .name{
    color: #0253B3;
    font-size: 23px;
    line-height: 29px;
    text-decoration: none;
    padding-right: 20px;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
    &:hover{
      text-decoration: underline;
    }
  }
  .review-name{
    color: #334150;
    text-decoration: none;
    font-size: 32px;
    &:hover{
      text-decoration: none;
    }
  }
  .rating-block{
    display: flex;
  }
  .commends{
    display: flex;
    align-items: center;
    margin-left: 10px;
  }
  .review-commends{
    color: #0253B3;
    padding: 7px 0;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
    &:hover{
      text-decoration: underline;
    }
  }
}
.descriptions-wrapper{
  padding: 20px 0;
  color: #334150;
  font-size: 32px;
  line-height: 40px;
}

.product-block{
  padding: 30px 0;
  border-top: 1px solid #C2D1D9;
  border-bottom: 1px solid #C2D1D9;
  .title{
    color: #334150;
    font-size: 32px;
    line-height: 40px;
    padding-bottom: 20px;
  }
  .product-info{
    max-width: 800px;
    width: 100%;
    display: flex;
    flex-flow: column;
    .info{
      display: flex;
      justify-content: space-between;
      .name-product{
        color: #7F8FA4;
        font-size: 23px;
        line-height: 29px;
        display: flex;
        align-items: center;
          .name-name{
            color: #334150;
            max-width: 300px;
            word-wrap: break-word;
        }
        p{
          padding: 0 15px;
        }
      }
    }
    .price-info{
      display: flex;
      align-items: flex-end;
      span{
        color: #7F8FA4;
        font-size: 16px;
        line-height: 20px;
        padding-right: 5px;
      }
      div{
        color: #334150;
        font-size: 23px;
        line-height: 24px;
      }
    }
  }
  .firms{
    color: #7F8FA4;
    font-size: 16px;
    line-height: 20px;
    padding-bottom: 30px;
  }
}

.all-product-wrapper{
  border-top: 1px solid #C2D1D9;
  max-width: 800px;
  width: 100%;
  .product{
    padding: 10px 0 7px;
    display: flex;
    justify-content: space-between;
    div{
      color: #334150;
      font-size: 16px;
      line-height: 20px;
    }
    span{
      color: #7F8FA4;
      font-size: 16px;
      line-height: 20px;
    }
  }
  .all-price{
    color: #334150;
    line-height: 27px;
    display: flex;
    font-size: 23px;
    padding: 20px 0 5px 0;
    justify-content: space-between;
    .cost-block{
      padding-left: 5px;
          }
    .t-b {
      font-size: 32px;
      .total-block {
        font-size: 32px;
        font-weight: 600;
      }
    }
  }
}

.customer-comments{
  padding: 40px 50px;
  background: #ffffff;
  margin-bottom: 86px;
  .title{
    color: #334150;
    font-size: 32px;
    line-height: 40px;
  }
  .all-product{
    margin: 20px 0;
  }
  .info-comments{
    display: flex;
    align-items: center;
    .name{
      padding:0 20px 5px 0;
      color: #01397C;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
    }
    .time{
      color: #7F8FA4;
      font-size: 12px;
      font-weight: 300;
    }
  }
  .descriptions{
    color: #334150;
    font-size: 14px;
    font-weight: 300;
    padding-bottom: 20px;
  }
  textarea{
    font-family: "Akrobat", sans-serif;
    box-sizing: border-box;
    height: 59px;
    width: 651px;
    border: 1px solid #C2D1D9;
    border-radius: 3px;
    resize: none;
    padding: 13px 11px;
    color: #334150;
    &::placeholder{
      color: #7F8FA4;
      font-size: 14px;
    }
  }
  .btn-wrapper{
    padding-top: 5px;
  }
}

.order_section_title {
  color: #334150;
  font-size: 32px;
  line-height: 40px;
}

.add_order_block {
  margin: 30px 0;
  .custom_input_wrapper {
    margin-right: 50px;
  }
  & > :last-child {
    margin: 0;
  }
}

.edit_btn_wrapper {
  display: flex;
  align-items: center;
  margin-top: 30px;
  .default_button_wrapper {
    margin-right: 15px;
  }
  & > :last-child {
    margin: 0;
  }
}

.add_order_info {
  color: #7F8FA4;
  font-size: 14px;
  font-weight: 300;
  line-height: 18px;
  margin: 20px 0 30px;
}

.orders_summary {
  margin: 30px 0;
  & > div {
    margin-right: 100px;
  }
  & > :last-child {
    margin: 5px 0 0;
  }
  div {
    .block_label {
      display: block;
      margin-bottom: 15px;
    }
    .block_value_currency {
      font-size: 23px;
    }
  }
}

.order_product_item {
  margin: 30px 0;
  display: flex;
  .custom_input_wrapper {
    max-width: 50%;
    margin-right: 30px;
  }
  .red_btn {
    margin-left: 40px;
  }
  &.order_product_item_error {
    .custom_count_field .count_field {
      border-color: #ff0000;
    }
  }
}
.error_after_button {
  color: #ff0000;
  font-size: 12px;
}

.add_product {
  .search_product_wrapper {
    width: 100%;
    margin-right: 50px;
  }
}
.order-detail {
  padding-bottom: 30px;
  .content-block-status {
    p {
      margin-right: 30px;
    }
  }
}
.content-block-wrapper {
  background-color: #FFFFFF;
  padding: 30px 30px 30px 30px;
  margin-bottom: 30px;
}
