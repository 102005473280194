.orders-main-block {
  .dashboard-section_content {
    display: block;
    margin-top: 30px;
    &:after {
      content: "";
      display: table;
      clear: both;
    }
  }
}

.dashboard_block.order-item, .order-item {
  position: relative;
  float: left;
  margin-right: 2%;
  width: 23.5%;
  cursor: pointer;
  &:nth-child(4n) {
    margin-right: 0;
  }
  .block_info_notif {
    display: inline-block;
    color: #ffffff;
    text-align: center;
    font-size: 12px;
    line-height: 22px;
    height: 20px;
    width: 20px;
    border-radius: 3px;
    background-color: #249AF3;
    margin-top: 10px;
  }
  .block_info_item {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    .block_value {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .block_value_currency {
      min-width: 90px;
      font-family: "Akrobat", sans-serif;
      text-align: right;
      i {
        font-size: 15px;
      }
    }
    .handler-hover-area {
      cursor: pointer;
      position: relative;
      justify-content: normal;
      &:hover {
        .block_value_all {
          height: auto;
          opacity: 1;
          p {
            display: flex;
            justify-content: space-between;
          }
        }
      }
    }
    .block_value_all_wrapper {
      margin-left: 5px;
      &>span {
        display: inline-block;
      }
    }
    .block_value_all {
      word-wrap: break-word;
      position: absolute;
      min-width: calc(100% + 30px);
      width: 100%;
      background-color: #FFFFFF;
      border: 1px solid rgba(127,143,164,0.5);
      border-radius: 4px;
      padding: 15px;
      top: 20px;
      right: -15px;
      height: 0;
      opacity: 0;
      overflow: hidden;
      z-index: 1;
      -webkit-transition: all ease .3s;
      -moz-transition: all ease .3s;
      -ms-transition: all ease .3s;
      -o-transition: all ease .3s;
      transition: all ease .3s;
      p {
        font-family: "Akrobat", sans-serif;
        color: #334150;
        width: 100%;
        font-size: 16px;
        display: none;
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #C2D1D9;
        &:first-child {
          padding-top: 0;
        }
        &:last-child {
          border-bottom: none;
          padding-bottom: 0;
        }
        span {
          i {
            font-style: normal;
          }
          &:first-child {
            i {
              float: none;
              display: block;
              &:last-child {
                color: #7F8FA4;
                font-size: 14px;
              }
            }
          }
          &:last-child {
            min-width: 90px;
            text-align: right;
          }
        }
      }
    }

  }
}


.page_options_wrapper{
  position: relative;
  width: 275px;
  height: 40px;
  background-color: #FFFFFF;
  .search_wrapper{
    display: flex;
    .search_settings{
      width: 275px;
      height: 40px;
      color: #7F8FA4;
      font-family: "Akrobat Regular", sans-serif;
      font-size: 14px;
      line-height: 20px;
      font-weight: 300;
      box-sizing: border-box;
      border: 1px solid #C2D1D9;
      border-radius: 3px;
      padding: 0 10px;
      input{
        &::placeholder{
          height: 18px;
          width: 40px;
          color: #7F8FA4;
          font-family: "Akrobat", sans-serif;
          font-size: 14px;
          line-height: 18px;
        }
      }
    }
    .search_input_icon{
      position: absolute;
      right: 5px;
      top: 9px;
    }
  }
}


/*div.select_wrapper{
  width: 275px;
  margin-left: 30px;
  background-color: #FFFFFF;
  -webkit-transition: all ease .3s;
  -moz-transition: all ease .3s;
  -ms-transition: all ease .3s;
  -o-transition: all ease .3s;
  transition: all ease .3s;
  &:hover {
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.05);
    cursor: pointer !important;
  }
  & > div.select_settings {
    display: none;
    height: 40px;
    color: #7F8FA4;
    font-family: "Akrobat Regular", sans-serif;
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;
    box-sizing: border-box;
    border: 1px solid #C2D1D9;
    border-radius: 3px;
    .select_settings_select {
      //background-color: #FFFFFF;
      background-color: #000;
      padding: 8px 12px;
      cursor: pointer !important;
      &::placeholder{
        font-family: "Akrobat", sans-serif;
      }
    }
    &:after {
      transform: scaleX(0);
    }
    &:before {
      border: none;
    }
    &:hover {
      &:before {
        border: none!important;
      }
    }
  }
}*/

div.select_wrapper {
  width: 275px;
  height: 40px;
  margin-left: 30px;
  background-color: #FFFFFF;
  -webkit-transition: all ease .3s;
  -moz-transition: all ease .3s;
  -ms-transition: all ease .3s;
  -o-transition: all ease .3s;
  transition: all ease .3s;
  .select__indicators, .select__menu * {
    cursor: pointer;
  }
  &:hover {
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.05);
  }
  div.select__value-container {
    height: 100%;
    cursor: text;
  }
  div.select_settings {
    height: 40px;
    color: #7F8FA4;
    font-family: "Akrobat Regular", sans-serif;
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;
    box-sizing: border-box;
    border: 1px solid #C2D1D9;
    border-radius: 3px;
    .select_settings_select {
      background-color: #FFFFFF;
      padding: 8px 12px;
    }
    &:after {
      transform: scaleX(0);
    }
    &:before {
      border: none;
    }
    &:hover {
      &:before {
        border: none!important;
      }
    }
  }
}


.select_settings_menu {
  border-radius: 3px;
  background-color: #FFFFFF;
  color: #7F8FA4;
  font-family: "Akrobat Regular", sans-serif;
  font-size: 14px;
  font-weight: 300;
  padding: 0 12px;
  min-width: 275px;
  border: 1px solid #C2D1D9;
  option {
    padding: 4px 0;
    cursor: pointer;
  }
}


.select_settings_icon{
  font-size: 16px!important;
  margin-right: 10px;
  margin-top: 4px;
}
.status_block {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
}

.status_title {
  color: #7F8FA4;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  span {
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin-right: 5px;
    color: red !important;
  }
}

.order_title {
  margin: 15px 0 10px;
  a {
    text-decoration: none;
  }
  h5 {
    color: #01397C;
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
  }
}

.order_title {
  margin: 15px 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  h5 {
    color: #01397C;
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
  }
  .comment{
    width: 20px;
    height: 20px;
    color: #fff;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 3px;
    font-size: 12px;
    padding: 0;
  }
}
.block_label{
  color: #7F8FA4;
  font-size: 12px;
  font-weight: 300;
  line-height: 15px;
  padding: 3px 0;
}
.block_info_item{
  display: flex;

  .block_value{
    color: #334150;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    height: 19px;
  }
  .product{
    color: #7F8FA4;
    font-size: 14px;
    line-height: 17px;
  }
}

.reviews-wrapper{
  display: flex;
  align-items: center;
  padding: 3px 0;

  .commends{
    color: #7F8FA4;
    font-size: 12px;
    font-weight: 300;
    line-height: 15px;
    cursor: pointer;
    height: 100%;
  }
  .order-commends{
    color: #7F8FA4;
    font-size: 14px;
    font-weight: 300;
    padding: 6px 20px;
  }
}
.phones{
  padding: 10px 0;
}
.expansion-block{
  padding-top: 10px;
  position: relative;
  overflow: hidden;
  height: 40px;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.expansion-block-active{
  height: 334px;
  .expansion-body{
    z-index: 1;
  }
}

.reviews-orders{
  display: flex;
  flex-direction: row !important;
}
.block_sub_value {
  color: #7F8FA4;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  svg {
    margin-right: 4px;
    fill: gray;
    font-size: 12px;
  }
}

.block_value_currency {
  padding: 2px;
  display: flex;
  color: #334150;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 19px;
}

.order_bottom_info {
  display: flex;
  justify-content: space-between;
  margin: 6px 0;
  .block_value_currency {
    font-size: 23px;
    line-height: 27px;
    align-self: flex-end;
  }
}
hr {
  width: 100%;
  height: 1px;
  background-color: #C2D1D9;
  margin-bottom: 20px;
}

.dashboard_block {
  background-color: #ffffff;
  border-radius: 5px;
  min-height: 197px;
  width: 24.2%;
  display: inline-block;
  padding: 15px 15px 10px;
  margin-bottom: 30px;
  hr {
    margin: 20px 0;
  }
}

.date_picker_wrapper{
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  .date_picker_inner{
    box-sizing: border-box;
    border: 1px solid #C2D1D9;
    border-radius: 3px;
    background-color: #FFFFFF;
    max-width: 130px;
    height: 41px;
    display: flex;
    align-items: center;
    width: 131px;

    p{
      color: #7F8FA4;
      font-size: 14px;
      font-weight: 300;
      line-height: 18px;
      padding-right: 35px;
    }
    input{
      color: #334150;
      height: 37px;
      cursor: pointer;
      font-family: "Akrobat", sans-serif;
      font-size: 14px;
    }
  }
  .date_picker_inner:first-of-type{
    margin-right: 13px;
  }
}
