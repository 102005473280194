.search-page{
  margin: 50px 0 92px;
  padding: 40px 50px 40px 50px;
  background: #fff;
  .transactions_columns {
    .table_header{
      .row_item{
        display: flex;
        align-items: center;
      }
    }
    .table_row:hover{
      background-color: #fff;
    }
    .table_row {
      & > :first-child {
        width: 24%;
        font-weight: 600;
      }
      & > :nth-child(2) {
        width: 24%;
      }
      & > :nth-child(3) {
        width: 24%;
      }
      & > :nth-child(4) {
        width: 14%;
        display: flex;
        p{
          font-weight: 600;
          padding-left: 5px;
        }
      }
      & > :last-child {
        width: 14%;
        justify-content: flex-end;
        display: flex;
        button{
          cursor: pointer;
          background: transparent;
          img.focus-LogoFavourites {
            display: none;
          }
          img.not-focus{
            display: block;
          }
        }
        button:hover{
          img.focus-LogoFavourites {
            display: block;
          }
          img.not-focus{
            display: none;
          }
        }
      }
    }
  }
  .transactions_columns_vin {
    width: 100%;
    .table_header {
      display: flex;
      .table_row{
        padding-top: 10px;
        padding-left: 5px;
        .row_item {
          display: flex;
          align-items: center;
          width: inherit;
        }
      }
    }
    .table_body {
      .table_row {
        border-top: 2px solid rgba(0, 0, 0, 0.1);
        text-decoration: none;
        padding-left: 5px;
        &:hover {
          -webkit-transition: all ease .5s;
          -moz-transition: all ease .5s;
          -ms-transition: all ease .5s;
          -o-transition: all ease .5s;
          transition: all ease .5s;
          background-color: #EDF2F8;
        }
        .row_item {
          width: inherit;
        }
      }
    }
  }
  .search-pagination {
    border-top: 2px solid rgba(0, 0, 0, 0.1);
    padding-top: 35px;
  }
  .title-wrapper{
    color: #334150;
    font-family: Akrobat;
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 20px;
  }
  .search-message{
    color: #7F8FA4;
    font-family: Akrobat;
    font-size: 16px;
    line-height: 20px;
    padding-bottom: 10px;
  }
}
