.custom_area_field{
  position: relative;
  width: 650px;
  svg{
    fill: #ff0000;
    cursor: pointer;
    position: absolute;
    top: 7px;
    right: 15px;
  }
}

