.select_component {
  height: 100%;
  & ~ .date_wrapper {
    margin-left: 20px;
  }
  .select__control--is-focused {
    box-shadow: none;
    border: 1px solid #65A3E8;
    .indicator_active {
      svg {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }
  }
  .select__control {
    height: 100%;
    border-radius: 3px;
    border: 1px solid #C2D1D9;
    font-size: 14px;
    &:hover {
      border: 1px solid #C2D1D9;
    }
    .select__placeholder {
      color: rgba(127, 143, 164, 0.5);
      //color: #7F8FA4;
    }
    .select__single-value {
      color: #7F8FA4;
    }
    .select__input {
      color: #7F8FA4;
      font-size: 14px;
      input {
        font-family: "Akrobat Regular", sans-serif !important;
        font-size: 12px;
      }
    }
    .select__indicator-separator {
      background-color: rgba(101, 163, 232, 0.5);
    }
    .select_indicator {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      svg {
        fill: #7F8FA4;
        font-size: 20px;
      }
    }
    .select__loading-indicator {
      span {
        background-color: #7F8FA4;;
      }
    }
    .select_close_btn {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px;
      cursor: pointer;
      svg {
        fill: #7F8FA4;
        font-size: 14px;
      }
    }
  }
  .select__menu {
    color: #7F8FA4;
    z-index: 9999;
    .select__menu-list {
      font-family: "Akrobat", sans-serif;
      font-size: 14px;
    }
  }
}

