.offers-block{
  position: relative;
  display: flex;
  align-items: flex-start;
  margin-bottom: 75px;
}

.empty-offer{
  height: 150px;
  background-color: #FFFFFF;
  box-shadow: 0 0 10px 0 rgba(161,161,161,0.5);
  padding-left: 30px;
  padding-top: 30px;
  padding-bottom: 40px;
  .title{
    color: #334150;
    font-family: Akrobat;
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 20px;
  }

}

.up-btn{
  position: absolute;
  bottom: 30px;
  right: -70px;
  transform: rotate(-90deg);
  color: #0253B3;
  font-family: Akrobat;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  cursor: pointer;
  background: transparent;
  display: flex;
  align-items: baseline;
  img{
    transform: rotate(90deg);
    padding: 0 5px;
  }
}
