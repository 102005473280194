.catalog-fourth-page {
  margin: 50px 150px;
  padding: 40px 50px 50px 50px;
  background: #ffffff;
  .catalog-hierarchy {
    font-family: Akrobat, sans-serif;
    font-size: 16px;
    padding-bottom: 20px;
    .catalog-link{
      text-decoration: none;
      color: #0253B3;
      -webkit-transition: all 0.3s linear;
      transition: all 0.3s linear;
      &:hover{
        text-decoration: underline;
        opacity: 0.7;
      }
    }
  }
  .model-info-wrapper {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #7F8FA4;
    font-family: "Akrobat", sans-serif;
    font-size: 16px;
    line-height: 20px;
    .model-info {
      margin-right: 10px;
    }
    span {
      margin-right: 10px;
      &:last-of-type {
        display:none;
      }
    }
  }
  .hr-line {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .search_settings {
    color: #7F8FA4;
    font-family: "Akrobat Regular", sans-serif;
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;
    box-sizing: border-box;
    input {
      &::placeholder {
        height: 18px;
        width: 40px;
        color: #7F8FA4;
        font-family: "Akrobat", sans-serif;
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
}

.search_wrapper_catalog {
  position: relative;
  background-color: #FFFFFF;
  border-radius: 3px;
  border: 1px solid #C2D1D9;
  height: 40px;
  display: flex;
  width: 90%;
  justify-content: space-between;
  &>div:first-child {
    width: calc(100% - 34px);
  }
  .search_input_icon {
    display: flex;
    align-items: center;
    width: 34px;
  }
  input {
    width: calc(100% - 34px);
    height: 38px;
    line-height: 38px;
    padding: 0 12px;
    font-size: 14px;
    font-family: "Akrobat Regular", sans-serif;
    &::placeholder {
      color: #7F8FA4;
    }
  }
  button {
    padding: 5px 7px;
    border-radius: 0;
    svg {
      font-size: 20px;
      fill: #C2D1D9;
    }
  }
}

.tree-wrapper {
  display: flex;
  flex-flow: row;
  .tree-catalog {
    width: 35%;
    .tree-view {
      width: 90%;
      margin-top: 20px;
      font-size: 16px;
      color: #0253B3;
    }
  }
  .catalog-parts-wrapper {
    width: 65%;
    .parts-icons {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      max-height: max-content;
      & > div {
        width: 23%;
        max-height: 130px;
        margin-right: 2%;
        margin-bottom: 2%;
        .detail-box {
          color: white;
          min-height: 130px;
          background-size: cover !important;
          display: flex;
          align-items: center;
          text-align: center !important;
          background: url("../../../assets/image/Group 9.png") no-repeat;
          & > span {
            display: inline;
            width: 100%;
          }
        }
        .image-block {
          text-decoration: none;
          height: 100%;
          min-height: 130px;
          background-size: cover !important;
          display: flex;
          align-items: center;
          text-align: center !important;
          & > span {
            text-decoration: none;
            background-color: hsla(0,0%,100%,.75);
            display: inline;
            font-weight: 700;
            width: 100%;
            color: black;
          }
        }
        -webkit-transition: all 0.3s linear;
        -moz-transition: all 0.3s linear;
        -ms-transition: all 0.3s linear;
        -o-transition: all 0.3s linear;
        transition: all 0.3s linear;
        &:hover{
          opacity: 0.8;
          transition-delay: 0.1s;
          cursor: pointer;
        }
      }
    }
    .title-catalog {
      margin-bottom: 20px;
      height: 29px;
      color: #334150;
      font-family: "Akrobat", sans-serif;
      font-size: 23px;
      line-height: 29px;
    }
  }
}

.highlight {
  background-color: yellow;
}