.catalog-fifth-page{
  margin: 50px 150px;
  padding: 40px 50px 50px 50px;
  background: #ffffff;
  .catalog-hierarchy{
    font-family: Akrobat, sans-serif;
    font-size: 16px;
    padding-bottom: 20px;
    .catalog-link{
      text-decoration: none;
      color: #0253B3;
      &:hover{
        text-decoration: underline;
        opacity: 0.7;
      }
    }
  }
  .model-info-wrapper {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #7F8FA4;
    font-family: "Akrobat", sans-serif;
    font-size: 16px;
    line-height: 20px;
    .model-info {
      margin-right: 10px;
    }
    span {
      margin-right: 10px;
      &:last-of-type {
        display:none;
      }
    }
  }
  .hr-line {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .part-chart-wrapper {
    display: flex;
    flex-flow: row;
    min-height: 1500px;
    .part-chart-info {
      width: 35%;
      .part-chart-title{
        font-size: 23px;
        margin-bottom: 30px;
      }
      .part-table {
        width: 90%;
        font-size: 14px;
        .table_row:hover{
          border-bottom: 1px solid rgba(47, 0, 232, 0.6);
          cursor: pointer;
          transition-delay: 0.1s;
        }
        .table_row {
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          height: 40px;
          width: 100%;
          text-decoration: none;
          -webkit-transition: all ease .5s;
          -moz-transition: all ease .5s;
          -ms-transition: all ease .5s;
          -o-transition: all ease .5s;
          transition: all ease .5s;
          & > :first-child {
            width: 10%;
          }
          & > :nth-child(2) {
            width: 70%;
          }
          & > :nth-child(3) {
            text-align: end;
            width: 10%;
          }
          & > :nth-child(4) {
            text-align: end;
            width: 10%;
          }
        }
      }
    }
    .part-chart-scheme {
      margin-top: 60px;
      width: 65%;
      .part-image-block {
        display: flex;
        overflow: hidden;
        position: relative;
        z-index: 0;
        height: 100%;
        width: 100%;
        .part-image {
          box-sizing: inherit;
          position: absolute;
          z-index: -1;
          width: inherit;
        }
        .responsive-content{
          box-sizing: inherit;
          position: absolute;
          width: inherit;
          height: inherit;
          display: block;
          div {
            position: absolute;
            cursor: pointer;
            opacity: 1;
            z-index: 1;
            &:hover {
              border: 2px solid #0253B3;
            }
          }
        }
      }
    }
  }
}

.part-dialog-wrapper {
  .title {
    color: #334150;
    font-size: 32px;
    line-height: 40px;
    padding-bottom: 30px;
  }
  .part-info-wrapper {
    display: flex;
    flex-flow: row;
    div {
      margin-right: 70px;
      .part-description {
        color: #7F8FA4;
        font-family: "Akrobat", sans-serif;
        font-size: 12px;
        font-weight: 300;
      }
      .part-information {
        color: #334150;
        font-family: "Akrobat", sans-serif;
        font-size: 14px;
      }
    }
  }
  .part-additional-info-wrapper {
    display: flex;
    flex-flow: row;
    margin-top: 20px;
    div {
      margin-right: 70px;
      .part-description {
        color: #7F8FA4;
        font-family: "Akrobat", sans-serif;
        font-size: 12px;
        font-weight: 300;
      }
      .part-information {
        color: #334150;
        font-family: "Akrobat", sans-serif;
        font-size: 14px;
      }
    }
  }
}

.catalog-part-info-icons:hover {
  transform: scale(1.2);
  transition: transform .25s ease;
}

.dialog-wrapper-mini {
    width: 840px !important;
}