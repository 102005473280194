@mixin transition_linear ($method: all, $trans: 0.3s) {
  -webkit-transition: $method ease $trans ;
  -moz-transition: $method ease $trans ;
  -ms-transition: $method ease $trans ;
  -o-transition: $method ease $trans ;
  transition: $method ease $trans ;
}

.app-head {
  background-color: #01397C;
  a {
    text-decoration: none;
  }
}

.flex-center-fixed{
  display: none;
}

.header-wrapper{
  height: 80px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.app-head_options {
  display: flex;
  align-items: center;
  .exit_btn {
    opacity: 0.5;
    color: #FFFFFF;
    font-family: "Akrobat Light", sans-serif;
    font-size: 14px;
    line-height: 13px;
    font-weight: 300;
    text-transform: none;
    @include transition_linear();
    &:hover {
      opacity: 1;
    }
  }
  .account-wrapper{
    display: flex;
    align-items: center;
  }
}

.log-out{
  margin-right: 30px;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: bold;
  line-height: 17px;
  text-align: right;
  background: transparent;
  cursor: pointer;
  &:hover{
    text-decoration: underline;
  }
}

.location-btn{
  display: flex;
  .default_button_wrapper{
    .default_button.default_button_outlined{
      margin-right: 10px;
      border: 1px solid #7F8FA4;
      color: #7F8FA4;
    }
    button{
      padding: 0;
      min-width: 100px;
      height: 29px;
    }
  }
}

.search_wrapper_location{
  width: 100%;
  margin-bottom: 20px;
  input{
    height: 41px;
    width: 100%;
    border: 1px solid #C2D1D9;
    border-radius: 3px;
    padding: 0 13px;
    font-size: 14px;
    color: #334150;
    font-family: "Akrobat", sans-serif;
    &::placeholder{
      color: #7F8FA4;
    }
  }
}
.info-search{
  padding-bottom: 40px;
  color: #334150;
  font-size: 16px;
  line-height: 20px;
}

.direction-table-group{
  width: 100%;
}

.scroll-custom {
  & > :nth-child(3) {
    background-color: #EDF2F8;
    & > div {
      border-radius: 2px;
      background:#0253B3 !important;
    }
  }
}

.app_head_options {
  display: flex;
  justify-content: space-between;
  .notification_btn {
    padding: 6px 10px;
    svg {
      fill: #ffffff;
      font-size: 28px;
    }
  }
}

.header{
  &.fixed-header-wrapper {
    padding-top: 248px;
    &>.container {
      opacity: 0;
    }
  }
  &>.container {
    height: 248px;

  }
  .fixed-header{
    background: #01397C;
    z-index: 10000;
    position: fixed;
    top: 0;
    left: 0;
    height: 0;
    align-items: center;
    display: flex;
    flex-flow: row-reverse;
    justify-content: space-between;
    opacity:  0;
    &.fixed-header-opacity {
      opacity: 1;
      height: 80px;
      @include transition_linear(height, 0.3s);
    }
    &.fixed-header-menu-open {
      padding-right: 167px;
    }
    .dashboard-head-content{
      padding: 0;
    }
    .header-wrapper{
      border: none;
      .flex-center{
        display: none;
      }
      .account-wrapper{
        border: none;
      }
    }
    .location-block{
      display: none;
    }
    .flex-center-fixed{
      display: block;
    }
    .dashboard-head-content{
      flex-flow: row-reverse;
      display: flex;
      .search_wrapper_dashboard{
        width: 471px;
        margin: 0;
      }
      .custom_radio{
        margin: 0 20px 0 0;
      }
    }
  }
}
.button_header_wrapper {
  min-width: 200px;
  .button_header_inner {
    text-transform: none;
    font-family: "Akrobat Light", sans-serif;
    color: #FFFFFF;
    font-size: 16px;
  }
}
.button_header_paper {
  min-width: 200px!important;
  ul {
    li {
      font-family: "Akrobat", sans-serif;
      font-size: 16px;
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
}
.icon-arrow-down {
  -webkit-transform:  rotate(90deg);
  -moz-transform:     rotate(90deg);
  -o-transform:       rotate(90deg);
  -ms-transform:      rotate(90deg);
  transform:          rotate(90deg);
}

.basket-btn{
  padding: 0 20px;
  display: flex;
  align-items: center;
  button{
    padding: 8px;
  }
  a{
    position: relative;
    cursor: pointer;
  }
  .item-basket{
    position: absolute;
    top: -5px;
    right: -2px;
    font-size: 12px;
    background-color: #249AF3;
    border-radius: 50%;
    height: 16px;
    padding: 0 4px;
    min-width: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
  }
}

.basket-btn.not-authorized{
  padding: 0;
  margin: 0 50px 0 10px;
  display: flex;
  align-items: center;
  a{
    position: relative;
    cursor: pointer;
  }
  .item-basket{
    position: absolute;
    top: -5px;
    right: -2px;
    font-size: 12px;
    background-color: #249AF3;
    border-radius: 50%;
    height: 16px;
    padding: 0 4px;
    min-width: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
  }
}

.menu-item {
  top: 52px !important;
}