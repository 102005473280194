.catalog-first-page{
   margin: 50px 150px;
   padding: 40px 50px 50px 50px;
   background: #ffffff;
  .catalog-fp{
    max-height: 400px;
    display: flex;
    flex-direction: column;
    padding-top: 50px;
    flex-wrap: wrap;
    text-align: left;
    align-content: flex-start;
  }
  .catalog-fp-big{
    max-height: 1290px;
    display: flex;
    flex-direction: column;
    padding-top: 50px;
    flex-wrap: wrap;
    text-align: left;
    align-content: flex-start;
  }
  .catalog-fp-medium{
    max-height: 800px;
    display: flex;
    flex-direction: column;
    padding-top: 50px;
    flex-wrap: wrap;
    text-align: left;
    align-content: flex-start;
  }
   .catalog-hierarchy{
     font-family: Akrobat, sans-serif;
     font-size: 16px;
     padding-bottom: 20px;
     .catalog-link{
       text-decoration: none;
       color: #0253B3;
       -webkit-transition: all 0.3s linear;
       transition: all 0.3s linear;
       &:hover{
         text-decoration: underline;
         opacity: 0.7;
       }
     }
   }
 }