.notification_list {
  & > :last-child {
    border-bottom: none;
  }
}

.notification_item {
  display: block;
  text-decoration: none;
  padding: 14px 0;
  border-bottom: 1px solid #C2D1D9;
  img {
    margin-right: 10px;
  }
}
.notification_item_link {
  display: block;
  text-decoration: none;
  img {
    margin-right: 10px;
  }
}

.notification_title_wrapper {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}

.notification_title {
  color: #334150;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  span {
    font-family: "Akrobat Bold", sans-serif;
  }
}

.notification_date {
  color: #7F8FA4;
  font-size: 12px;
  font-weight: 300;
  line-height: 15px;
}
