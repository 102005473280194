.dashboard-favorites{
  background-color: #EDF2F8;
  .title-block{
    padding: 30px 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.favorites-block{
  padding-bottom: 50px;
  display: flex;
  align-items: center;
  .parts{
    background: url("../../../assets/image/Group 9.png") no-repeat;
  }
  .car{
    background: url("../../../assets/image/Group 8.png") no-repeat;

  }
}

.info-block{
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  padding: 20px 30px;
  margin-right: 1.33%;
  height: 200px;
  width: 24%;
  text-decoration: none;
  overflow: hidden;
  text-overflow: ellipsis;
  background: radial-gradient(circle, rgba(2,83,179,0.8) 0%, #002046 100%);
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  &:hover{
    opacity: 0.8;
    transition-delay: 0.1s;
  }
  h3{
    overflow: hidden;
    text-overflow: ellipsis;
    color: #FFFFFF;
    font-size: 23px;
    font-weight: 600;
    line-height: 29px;
  }
  span{
    color: #7F8FA4;
    font-size: 16px;
    line-height: 20px;
  }

}