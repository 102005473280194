.search-page{
  margin: 50px 0 92px;
  padding: 40px 50px 30px 50px;
  background: #fff;
  .transactions_columns {
    .table_header{
      .row_item{
        display: flex;
        align-items: center;
      }
    }
    .table_row {
      & > :first-child {
        width: 24%;
        font-weight: 600;
      }
      & > :nth-child(2) {
        width: 24%;
      }
      & > :nth-child(3) {
        width: 24%;
      }
      & > :nth-child(4) {
        width: 14%;
        display: flex;
        p{
          font-weight: 600;
          padding-left: 5px;
        }
      }
      & > :last-child {
        width: 14%;
        justify-content: flex-end;
        display: flex;
        button{
          cursor: pointer;
          background: transparent;
        }
      }
    }
  }
}