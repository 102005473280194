.filters-offers-wrapper {
    margin-right: 30px;
    .default_button_wrapper{
      button{
        width: 100%;
        margin-top: 15px;
        .MuiButton-label-44{
          color: #FFFFFF;
          font-family: Akrobat;
          font-size: 14px;
          font-weight: bold;
          line-height: 17px;
          text-align: center;
        }
      }
    }
}
.filters-offers{
  width: 250px;
  background: #fff;
  padding: 30px;
  .title{
    padding-bottom: 20px;
    color: #334150;
    font-size: 32px;
    line-height: 40px;
  }
  * > div input{
    height: 18px;
    width: 16px;
    color: #334150;
    font-family: Akrobat;
    font-size: 14px;
    line-height: 18px;
  }
}

.filters-search{
  box-sizing: border-box;
  position: relative;
    input{
      height: 41px;
      width: 100%;
      border: 1px solid #C2D1D9;
      border-radius: 3px;
      background-color: #FFFFFF;
      font-family: "Akrobat", sans-serif;
      font-size: 14px;
      line-height: 18px;
      padding: 0 45px 0 20px;
      color: #334150;
      &::placeholder{
        color: #7F8FA4;
        font-family: Akrobat;
        font-size: 14px;
        font-weight: 300;
        line-height: 18px;
      }
    }
    .btn_search{
      position: absolute;
      right: 12px;
      cursor: pointer;
      background: transparent;
      top: calc(50% - 9px);
      &:hover{
        opacity: 0.7;
      }
    }
}

.setting-block * li{
  color: #334150;
  font-family: Akrobat;
  font-size: 14px;
}

.name-option{
  color: #334150;
  font-family: Akrobat;
  font-size: 14px;
}

.filters-field{
  display: flex;
  flex-flow: column;
  margin-top: 25px;
  padding-top: 30px;
  border-top: 2px solid rgba(0, 0, 0, 0.1);

  .descriptions{
    color: #7F8FA4;
    font-size: 12px;
    font-weight: 300;
    line-height: 18px;
  }
  .filters-input{
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .arrow{
      box-sizing: border-box;
      height: 1px;
      width: 10px;
      background: #C2D1D9;
    }
    input{
      font-family: "Akrobat", sans-serif;
      box-sizing: border-box;
      height: 41px;
      width: 75px;
      border: 1px solid #C2D1D9;
      border-radius: 3px;
      padding: 0 12px;
      &::placeholder{
        color: #334150;
        font-family: Akrobat;
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
}



.rc-slider-handle{
  border: solid 3px #0253B3;
  &:hover {
    border: solid 3px #0253B3;
  }
  &:active{
    border: solid 3px #0253B3;
  }
}
.rc-slider-track{
  background: #0253B3;
}
.rc-slider-handle-click-focused{
  border: solid 3px #0253B3;
  &:focus{
    border: solid 3px #0253B3;
  }
}
