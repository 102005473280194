.about_project_wrapper{
  background: #FFF;
  padding: 40px 50px;
  margin: 30px 0 44px 0;
  display: flex;
  align-items: center;
  .text_info{
    width: 50%;
    color: #334150;
    font-size: 16px;
    line-height: 20px;
    margin-right: 67px;
  }
  .logo_wrapper_block{
    display: flex;
    align-items: center;
    >div:first-of-type{
      margin-right: 47px;
    }

  }
}
