.preloader_wrapper {
  //position: absolute;
  ////top: 48px;
  //top: 25.3%;
  //left: 0;
  width: 100%;
  z-index: 9999;
  & > div {
    height: 2px;
    background-color: #0E1832;
    &> div {
      background-color: #FFFFFF;
    }
  }
}