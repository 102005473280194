.buy-error{
  span{
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
  }
  h1{
    color: #334150;
    font-family: Akrobat;
    font-size: 32px;
    line-height: 40px;
    font-weight: 300;
    padding-bottom: 25px;
    border-bottom: 1px solid #C2D1D9;
  }
  .default_button_wrapper{
    margin-top: 25px;
    .buy-error-ok{
      cursor: pointer;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    button{
      /*margin-top: 20px;*/
      span{
        position: relative;
        width: auto;
        height: 15px;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
      }
    }
  }
}


.info-success-buy{
  display: flex;
  width: 420px;
  justify-content: space-between;
  height: 50px;
  align-items: center;
  padding: 20px;
  position: fixed;
  left: 50%;
  bottom: 10%;
  transform: translate(-50%);
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
  background: #fff;
  z-index: 1000000;
  border-radius: 5px;
  & > div{
    display: flex;
    flex-flow: row;
    align-items: center;
    & > p{
      color: #334150;
      font-family: Akrobat;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      margin-bottom: 0;
    }
    & > span{
      margin-right: 5px;
      & > img{
        margin-top: -2.5px;
      }
    }
  }

  span.close{
    cursor: pointer;
  }
}

button:disabled{
  background: grey;
  opacity: 0.65;
  cursor: not-allowed;
}

