.auth_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 150px;
  background: #01397C;
  height: 80px;
  p {
    color: #ffffff;
    font-size: 14px;
    line-height: 13px;
    margin-right: 29px;
  }
  .default_button_transparent{
    min-width: 147px;
    height: 41px;
    font-size: 14px;
    padding: 0;
  }
}

.auth_container {
  width: 100%;
  min-height: 600px;
  height: calc(100vh - 80px);
  background-color: #01397C;
  padding: 20px 150px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  form {
    width: 300px;
    position: relative;
  }
  .custom_input_wrapper {
    margin-bottom: 20px;
  }
  .default_button_wrapper {
    margin-top: 10px;
  }
}

.auth_bg {
  background: url("../../assets/image/auth_bg.png") no-repeat 50% 50%;
  -webkit-background-size: cover;
  background-size: cover;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding: 50px 70px 50px 100px;
  color: #ffffff;
  font-size: 16px;
  line-height: 24px;
  p{
    width: 370px;
    color: #FFFFFF;
    font-family: Akrobat;
    font-size: 16px;
    line-height: 24px;
  }

}

.auth_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  .auth_content_form {
    padding-bottom: 30px;
    .auth_error {
      bottom: 35px;
    }
  }
}

.auth-box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  & > :first-child {
    width: 50%;
  }
  & > :last-child {
    width: 50%;
    background-color: #ffffff;
  }
}

.auth-block_head {
  color: #334150;
  font-size: 32px;
  line-height: 40px;
  font-weight: 400;
  margin-bottom: 50px;
  position: relative;
  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom: -10px;
    width: 50px;
    height: 1px;
    background-color: #0253B3;
  }
}

.auth_logo {
  border-radius: 20px;
  margin-bottom: 40px;
  margin-top: -100px;
  img {
    border-radius: 10px;
  }
}

.logo_center {
  margin-top: 120px;
}

.auth_default_text {
  color: #A1AEB7;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin: 30px 0;
}

.auth_link {
  color: #0253B3;
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
  margin-top: 40px;
  position: absolute;
  bottom: 50px;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  &:hover {
    text-decoration: underline;
  }
}

.auth_btn_wrapper {
  width: 300px;
  margin-top: 41px;
}

.form_start {
  margin-top: -10vh;
}

.auth_error {
  color: #ff0000;
  font-size: 14px;
  text-align: center;
  position: absolute;
  bottom: -24px;
  width: 100%;
}

.auth-block_wrapper {
  width: 300px;
  p {
    color: #01397C;
    font-size: 16px;
    line-height: 24px;
  }
  .default_button_wrapper {
    margin-top: 30px;
  }
}