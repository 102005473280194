.popper_wrapper {
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
  color: #334150;
  font-family: "Akrobat", sans-serif;
  font-size: 14px;
  line-height: 17px;
  padding: 5px 20px;
  width: 420px;
  position: relative;
  margin-top: 10px;
  &.location_popper {
    width: 270px;
    border-radius: 3px;
    margin: 15px 0 0 0;
    left: 50px;
    padding: 20px;
    &:before {
      content: "";
      display: block;
      width: 10px;
      height: 10px;
      position: absolute;
      top: -5px;
      right: 50px;
      background-color: #ffffff;
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}