.container-profile {
  .customer-page {
    padding: 40px 50px;
    margin-bottom: 57px;
    .reviews-wrapper{
      padding: 0 0 30px;

    }
  }
  .reviews-wrapper {
    .descriptions-wrapper{
      padding: 0 0 40px;
    }
    .descriptions-wrapper-contact{
      padding: 30px 0 40px 0;
    }
    .fields-inline{
      .custom_input_wrapper .custom_input input{
        color: #334150;
      }
    }
  }
  .product-block{
    .fields-inline{
      .custom_input_wrapper:first-of-type {
        .custom_input {
          input{
            color: #7F8FA4;

          }
        }
      }
    }
  }
}

.line_separator {
  background-color: #C2D1D9;
  width: 100%;
  height: 1px;
  margin-top: 30px;
}
.span_label {
  color: #334150;
  font-size: 10px;
  line-height: 25px;
}
.customer-page .reviews-wrapper .string_link {
  font-family: "Akrobat", sans-serif;
  color: #0253B3;
  font-size: 16px;
  line-height: 16px;
  cursor: pointer;
  margin-top: 20px;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  &:hover {
    text-decoration: underline;
  }
}

.info_pass_google{
  color: #334150;
}

.settings_wrapper {
  &>img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
  }
}
.fields-inline {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  &>div {
    margin-right: 40px;
  }
}

.check_wrapper {
  padding: 7px 0 0;
}
.error_after_button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0;
  span {
    color: #A1AEB7;
    line-height: 14px;
    font-size: 12px;
  }
  img {
    margin-right: 7px;
  }
}
