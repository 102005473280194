.dialog-delete-wrapper{
  .close{
    cursor: pointer;
    position: absolute;
    top: 17px;
    right: 25px;
  }
}
.modal-favorites{
  header{
    span{
      position: absolute;
      top: 17px;
      right: 25px;
      cursor: pointer;
    }
    h1{
      font-size: 36px;
      font-weight: 300;
      margin-bottom: 20px;
    }
    h4{
      font-weight: 200;
      margin-bottom: 30px;
      color: #334150;
      font-family: Akrobat;
      font-size: 14px;
      line-height: 17px;
    }
  }
  footer{
    padding: 30px 0 0;
    display: flex;
    border-top: 1px solid #C2D1D9;
    .default_button_wrapper:first-child{
      margin-right: 10px;
    }
  }
  .main-block{
    display: flex;
    flex-flow: column;
    width: 90%;
    .list-favourites{
      display: flex;
      flex-flow: row;
      justify-content: flex-start;
      margin-bottom: 25px;
      width: 740px;
      .item-list-favorites{
        margin-right: 30px;
        width: 310px;
        height: 175px;
        position: relative;
        flex: 0 0 auto;
        span{
          position: absolute;
          top: 10px;
          right: 27px;
        }
        img{
          width: 100%;
          margin: auto;
        }
        .name-list-favorites{
          position: absolute;
          bottom: 15px;
          margin: 5px 15px;
          color: white;
          white-space: nowrap;
          overflow: hidden;
          width: 280px;
          h4{
            font-size: 24px;
            font-weight: 400;
          }
          p{
            font-size: 16px;
            font-weight: 200;
            color: #4c6684;
          }
        }
      }
    }
    .comment-favourites{
      display: flex;
      flex-flow: column;
      position: relative;
      color: #7F8FA4;
      span{
        color: #7F8FA4;
        font-family: Akrobat;
        font-size: 14px;
        font-weight: 300;
        line-height: 18px;
      }
      .comments{
        height: 60px;
        border: 1px solid #C2D1D9;
        border-radius: 3px;
        margin-bottom: 35px;
        overflow: hidden;
        padding: 10px;
        margin-top: 5px;
        font-family: Akrobat;
        font-size: 14px;
        font-weight: normal;
        resize: none;
        overflow-y: auto;
      }
      .comments::placeholder{
        color: #7F8FA4;
        font-family: Akrobat;
        font-size: 14px;
      }
    }
  }
}

.list-favourites.scrollbar.scrollbar-primary{
  padding-bottom: 20px;
}

.scrollbar {
  overflow-x: auto;
  //cursor: pointer;
}

.scrollbar-primary::-webkit-scrollbar {
  height: 5px;
  background-color: #C2D1D9;
  border-radius: 10px;
}

.scrollbar-primary::-webkit-scrollbar-thumb {
  height: 5px;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #0253B3;
}
