.orders-main-block{
  font-family: "Akrobat", sans-serif;
  & > .title-block{
    & > h1 {
      margin: 50px 0 30px 0;
    }
  }
}

.orders-pagination{
  //margin-top: -150px;
  padding-bottom: 60px;
}
.date_picker_inner{
  position: relative;
  p{
    width: 12px;
    //color: #7F8FA4;
    position: absolute;
    top: calc(50% - 9px);
    left: 12px;
    color: red;
  }
  .myDatePicker{
    background: transparent;
    padding-left: 66px;
    width: 100%;
  }
}


