.catalog-third-page {
  margin: 50px 150px;
  padding: 40px 50px 50px 50px;
  background: #ffffff;
  .catalog-hierarchy {
    font-family: Akrobat, sans-serif;
    font-size: 16px;
    padding-bottom: 20px;
    .catalog-link {
      text-decoration: none;
      color: #0253B3;
      -webkit-transition: all 0.3s linear;
      transition: all 0.3s linear;
      &:hover {
        text-decoration: underline;
        opacity: 0.7;
      }
    }
  }
  .title-wrapper {
    padding-bottom: 29.5px;
  }
  .catalog-flex-center-start {
    align-items: center;
    justify-content: flex-start;
    .catalog-select-wrapper {
      margin-right: 19px;
      margin-bottom: 5px;
      width: 275px;
      background-color: #FFFFFF;
      -webkit-transition: all ease .3s;
      -moz-transition: all ease .3s;
      -ms-transition: all ease .3s;
      -o-transition: all ease .3s;
      transition: all ease .3s;
      &:hover {
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.05);
        cursor: pointer !important;
      }
    }
    .transactions_columns {
      width: 100%;
      .table_header {
        display: flex;
        .table_row{
          padding-top: 10px;
          padding-left: 5px;
          .row_item {
            display: flex;
            align-items: center;
            width: inherit;
          }
        }
      }
      .table_body {
        .table_row {
          text-decoration: none;
          padding-left: 5px;
          &:hover {
            -webkit-transition: all ease .5s;
            -moz-transition: all ease .5s;
            -ms-transition: all ease .5s;
            -o-transition: all ease .5s;
            transition: all ease .5s;
            background-color: #EDF2F8;
          }
          .row_item {
            width: inherit;
          }
        }
      }
    }
    .catalog-pagination {
      border-top: 2px solid rgba(0, 0, 0, 0.1);
      padding-top: 35px;
    }
  }
}
