.dashboard-head{
  background: url("../../assets/image/bg.png") no-repeat;
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  .app-head{
    background: transparent;
    padding: 0;
  }
}


.dashboard-head-content{
  padding: 30px 0 50px;

  .descriptions{
    padding: 52px 0;
    display: flex;
    color: #FFFFFF;
    font-size: 23px;
    //font-weight: bold;
    line-height: 22px;
    align-items: center;
    img{
      margin-right: 30px;
    }
  }
}

.search_wrapper_dashboard {
  margin-bottom: 20px;
  position: relative;
  width: 571px;
  input {
    height: 51px;
    width: 100%;
    border: 1px solid #FFFFFF;
    border-radius: 3px;
    background-color: transparent;
    font-family: "Akrobat", sans-serif;
    font-size: 16px;
    font-weight: 300;
    padding: 0 45px 0 20px;
    line-height: 41px;
    color: #fff;
    &::placeholder{
      color: #7F8FA4;
    }
  }
  .btn_search{
    position: absolute;
    right: 20px;
    cursor: pointer;
    background: transparent;
    top: calc(50% - 9px);
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
    &:hover{
      opacity: 0.7;
    }
  }
}

.search-radio-box{
  display: flex;
  .custom_radio{
    margin: 0 30px 0 0;
    >span{
      padding: 0;
    }
    span{
      font-family: "Akrobat", sans-serif;
      color: #FFFFFF;
      font-size: 14px;
      line-height: 17px;
    }
    span:last-of-type{
      padding-left: 5px;
    }
  }
}


.header {
  background: #01397C;
  .descriptions {
    display: none;
  }
}