.default_button_wrapper {
  &.default_button_wrapper_relative {
    position: relative;
  }
  .default_button {
    text-align: center;
    display: inline-flex;
    font-family: "Akrobat SemiBold", sans-serif;
    font-size: 14px;
    font-weight: 600;
    height: 40px;
    min-width: 147px;
    padding: 0 10px;
    border-radius: 3px;
    box-shadow: none;
    white-space: nowrap;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
    &:disabled {
      opacity: 0.7;
    }
    &.default_button_contained {
      background-color: #0253B3;
      color: #ffffff;
      border: 1px solid #0253B3;
      &.button_contained_filter {
        width: 100%;
        margin-top: 15px;
      }
    }
    &.default_button_outlined{
      color: #ffffff;
      border: 1px solid #ffffff;
      &.cancel_btn {
        border: 1px solid #7F8FA4;
        color: #7F8FA4;
      }
      &.delete_btn {
        border: 1px solid #D0021B;
        color: #D0021B;
      }
      &.grey_btn {
        border: 1px solid #7F8FA4;
        color: #7F8FA4;
      }
    }
    div {
      color: #ffffff;
    }
    &.google-btn {
      margin-bottom: 30px;
      margin-top: 10px;
      background-color: #EDF2F8;
      color: #0253B3;
      text-transform: uppercase;
      justify-content: center;
      cursor: pointer;
      align-items: center;
      &:hover {
        background-color: #DADEF8;
      }
      img {
        position: absolute;
        left: 30px;
      }
    }
  }
  .full_btn_width {
    width: 100%;
  }
}

