@charset "UTF-8";
.dialog-favorites .title {
  color: #334150;
  font-size: 32px;
  line-height: 40px;
  padding-bottom: 20px; }

.dialog-favorites .name-items {
  padding: 30px;
  display: flex;
  flex-flow: column;
  border: 1px solid #C2D1D9;
  border-radius: 5px;
  margin-bottom: 40px; }
  .dialog-favorites .name-items span {
    color: #334150;
    font-size: 23px;
    font-weight: 600;
    line-height: 29px; }
  .dialog-favorites .name-items p {
    color: #7F8FA4;
    font-size: 16px;
    line-height: 20px; }

.dialog-favorites .dialog-field-change {
  flex-flow: row;
  justify-content: space-between; }
  .dialog-favorites .dialog-field-change input {
    font-size: 14px;
    color: #334150; }
    .dialog-favorites .dialog-field-change input::placeholder {
      color: #7F8FA4; }
  .dialog-favorites .dialog-field-change .custom_input_wrapper:last-of-type {
    position: relative; }
    .dialog-favorites .dialog-field-change .custom_input_wrapper:last-of-type:before {
      position: absolute;
      font-family: "Akrobat", sans-serif;
      content: "KM";
      right: 12px;
      z-index: 11;
      color: #7F8FA4;
      font-size: 14px;
      font-weight: 300;
      line-height: 18px;
      top: calc(50% - 9px); }

.dialog-favorites .frame-number .frame-text {
  padding-bottom: 40px;
  color: #334150;
  font-size: 14px;
  line-height: 17px; }

.dialog-favorites .frame-number .custom_input_wrapper:before {
  display: none; }

.dialog-favorites .block-info .textarea-block .custom_input_wrapper:last-of-type {
  position: relative;
  width: 155px !important;
  margin-left: 50px; }
  .dialog-favorites .block-info .textarea-block .custom_input_wrapper:last-of-type:after {
    position: absolute;
    content: "км";
    top: calc(50% - 9px);
    right: 12px;
    font-size: 14px;
    font-family: "Akrobat Light", sans-serif;
    color: #C2D1D9; }

.dialog-favorites .block-info .descriptions {
  padding-bottom: 30px;
  font-size: 12px;
  color: #7F8FA4; }

.dialog-favorites .block-info .favorites-button {
  padding-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .dialog-favorites .block-info .favorites-button button {
    position: relative;
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    width: 310px;
    height: 172px;
    padding: 17px 25px;
    cursor: pointer;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear; }
    .dialog-favorites .block-info .favorites-button button:hover {
      opacity: 0.8; }
    .dialog-favorites .block-info .favorites-button button p {
      color: #FFFFFF;
      font-size: 19.81px;
      font-weight: 600;
      line-height: 25px; }
    .dialog-favorites .block-info .favorites-button button span {
      color: #7F8FA4;
      font-size: 13.78px; }
  .dialog-favorites .block-info .favorites-button button:first-of-type {
    background: url("../../../assets/image/Group 9.png") no-repeat; }
  .dialog-favorites .block-info .favorites-button button:last-of-type {
    background: url("../../../assets/image/Group 8.png") no-repeat; }
  .dialog-favorites .block-info .favorites-button .active:before {
    content: "";
    position: absolute;
    top: 19px;
    right: 19px;
    background: url("../../../assets/image/Path.png") no-repeat;
    width: 21px;
    height: 15px; }

.dialog-favorites .block-info .label-block {
  display: flex;
  flex-flow: column;
  color: #7F8FA4;
  font-size: 14px;
  font-weight: 300;
  line-height: 18px; }

.dialog-favorites .adding_options {
  margin: 10px 0 63px;
  display: flex; }
  .dialog-favorites .adding_options > p {
    position: relative;
    cursor: pointer;
    margin-right: 30px;
    color: #7F8FA4;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 20px;
    transition: all .2s linear; }
    .dialog-favorites .adding_options > p:hover {
      color: #334150; }
    .dialog-favorites .adding_options > p.active {
      color: #334150; }
      .dialog-favorites .adding_options > p.active:before {
        position: absolute;
        content: "";
        bottom: -10px;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: #81aada; }
  .dialog-favorites .adding_options .vin_num {
    padding: 20px;
    width: 506px;
    border: 1px solid #0253B3;
    border-radius: 5px;
    background-color: rgba(2, 83, 179, 0.05);
    color: #0253B3;
    font-size: 23px;
    font-family: "Akrobat SemiBold", sans-serif;
    margin-bottom: -30px; }

.dialog-favorites h4 {
  color: #334150;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 30px; }

.dialog-favorites .table_container {
  display: flex;
  flex-flow: column;
  width: 100%;
  align-items: center;
  justify-content: center; }
  .dialog-favorites .table_container .row {
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center; }
    .dialog-favorites .table_container .row .row_item {
      width: 30%; }
      .dialog-favorites .table_container .row .row_item:last-of-type {
        width: 10%;
        display: flex;
        justify-content: flex-end; }
  .dialog-favorites .table_container.table_header {
    color: #7F8FA4;
    font-family: "Akrobat Light", sans-serif;
    padding-bottom: 16px; }
  .dialog-favorites .table_container.table_body .row {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding: 20px 0; }
    .dialog-favorites .table_container.table_body .row .row_item:first-child {
      font-family: "Akrobat SemiBold", sans-serif; }
    .dialog-favorites .table_container.table_body .row .row_item:last-child {
      font-family: "Akrobat SemiBold", sans-serif; }
    .dialog-favorites .table_container.table_body .row .row_item .not_chosen {
      border-radius: 50%;
      height: 20px;
      width: 20px;
      border: 1px solid #C2D1D9;
      margin-right: 20px; }
    .dialog-favorites .table_container.table_body .row .row_item img {
      margin-right: 20px; }

.default_dialog_root {
  top: 20%; }

.table_body_starting_search > .table_row {
  cursor: pointer; }
  .table_body_starting_search > .table_row:hover {
    background-color: #EDF2F8; }
