.login_to_access_wrapper{
  padding: 72px 0 65px;
  width: calc(100% - 150px);
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  background-color: #FFFFFF;
  box-shadow: 0 4px 16px 0 rgba(0,0,0,0.15);
  .title {
    margin: 40px 0 10px;
    font-size: 32px;
    letter-spacing: 0;
    line-height: 40px;
  }
  .description {
    font-size: 16px;
    letter-spacing: 0;
    line-height: 20px;
    span {
      color: #0253B3;
      text-decoration: underline;
      transition: all 0.3s linear;
      cursor: pointer;
      &:hover {
        text-decoration: none;
      }
    }
  }
}