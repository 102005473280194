.check_field_wrapper {
  color: transparent;
  display: flex;
  margin: 7px 0;
  label {
    margin: 0;
    & > :first-child > :last-child > :first-child {
     font-family: "Akrobat", sans-serif!important;
      width: 17px !important;
      height: 17px !important;
      top: 1px !important;
      left: 1px !important;
    }
    & > :last-child {
      color: #334150;
      font-size: 14px;
      margin-left: 5px;
      font-weight: 400;
      font-family: "Akrobat", sans-serif;
    }
  }
  .custom_check{
    padding: 0;
  }
}