.loader_wrapper{
  position: fixed;
  top: 0;
  left: 0;
  align-content: center;
  overflow: auto;
}

.basket-page.container{
  padding: 40px 150px;
  .title{
    padding: 50px 0 30px;
    color: #334150;
    font-size: 60px;
    line-height: 75px;
  }
  .basket{
    padding: 40px 50px;
    background: #ffffff;
  }
}

.basket-items{
  margin-bottom: 30px;
  border: 1px solid #C2D1D9;
  border-radius: 5px;
  width: 100%;
  padding: 20px 30px 30px 30px;
  position: relative;
  .loader_wrapper{
    position: absolute;
  }
  .item-name{
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    padding-bottom: 20px;
    .name{
      padding-right: 5px;
      color: #0253B3;
      font-size: 16px;
      line-height: 20px;
      -webkit-transition: all 0.3s linear;
      -moz-transition: all 0.3s linear;
      -ms-transition: all 0.3s linear;
      -o-transition: all 0.3s linear;
      transition: all 0.3s linear;
      text-decoration: none;
      &:hover{
        text-decoration: underline;
      }
    }
    .reviews-wrapper{

      display: flex;
      flex-flow: row;
      align-items: flex-end;
      padding: 0;
      margin: 0 5px;
      .commends{
        cursor: default;
        margin: 0;
        padding-left: 5px;
      }
      span{
        font-size: 14px!important;
        align-self: flex-end;
      }
    }
    .address-block{
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      margin-left: 45px;
      cursor: pointer;
      .location{
        width: 12px;
        height: 15px;
        align-self: center;
        margin-right: 4px;
      }
      .address-link{
        	color: #0253B3;
          font-family: Akrobat;
          font-size: 14px;
      }
    }
  }

}

.product-block-wrapper{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 15px;
  max-width: 750px;
  width: 100%;
  .name-product{
    display: flex;
    flex-flow: column;
    width: 50%;
    .info{
      color: #7F8FA4;
      font-size: 12px;
      line-height: 18px;
    }
    .name-info{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: #7F8FA4;
      font-size: 23px;
      line-height: 29px;
      p{
        padding: 0 15px;
      }
      div{
        color: #334150;
      }
    }
    .firms{
      color: #7F8FA4;
      font-size: 16px;
      line-height: 20px;
    }
  }
  .info-item-block{
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    width: 250px;
    .quantity-block{
      display: flex;
      flex-flow: column;
      height: 66px;
      width: 100px;
      p{
        color: #7F8FA4;
        font-size: 12px;
        font-weight: 300;
        line-height: 18px;
      }
      .quantity{
        display: flex;
        align-items: center;
        height: 41px;
        border: 1px solid #C2D1D9;
        border-radius: 3px;
        margin-right: 5px;
        width: 100px;
      }
      span{
        display: flex;
        align-items: center;
        justify-content: center;
        color: #334150;
        font-size: 14px;
        padding: 0 8px;
      }
      button{
        font-size: 14px;
        color: #334150;
        background: transparent;
        cursor: pointer;
        padding: 0 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        border: none;
      }
    }
    .price{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      color: #334150;
      font-size: 23px;
      line-height: 27px;
    }
  }
}

.basket-items-wrapper{
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
  .delete-item{
    font-family: "Akrobat", sans-serif;
    color: #D0021B;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
    background: transparent;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
    &:hover{
      opacity: 0.7;
    }
  }
}

.price-wrapper{
  border-top: 1px solid #C2D1D9;
  padding: 10px 0 30px;
  margin-top: 10px;
  max-width: 750px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  div{
    color: #334150;
    font-size: 32px;
    font-weight: 600;
    >span{
      font-size: 32px;
      font-weight: 400;
    }
  }
  span{
    color: #334150;
    font-size: 23px;
    line-height: 38px;
  }
}

.btn-wrapper-item{
  padding-top: 30px;
  border-top: 1px solid #C2D1D9;
}

.green-btn{
  height: 40px;
  width: 146px;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(127,143,164,0.5);
  color: #FFFFFF;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  background-color: #16D761;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  font-family: "Akrobat", sans-serif;
  cursor: pointer;
  &:hover{
    opacity: 0.7;
  }
}

.product-total-wrapper{
  padding-top: 10px;
  display: flex;
  flex-flow: column;
  border-top: 1px solid #C2D1D9;
  p{
    color: #334150;
    padding-bottom: 7px;
    font-size: 23px;
    line-height: 29px;
    >span{
      font-weight:600;
    }
  }
  .info-price{
    color: #334150;
    font-size: 32px;
    line-height: 38px;
    padding-bottom: 30px;
    font-weight:600;
    span{
      font-weight:400;
    }
  }
 .btn-wrapper{
   display: flex;
   .default_button_wrapper{
     margin-right: 13px;
   }
 }
}

.no-item-basket{
  color: #7F8FA4;
  font-size: 16px;
  line-height: 20px;
  background: #ffffff;
  padding: 40px 50px;
  a{
    color: #0253B3;
    padding: 0 3px;
  }
}

.basket-dialog{
  width: 840px !important;
  .basket-dialog-number{
    & > p{
      height: 40px;
      width: 731px;
      color: #334150;
      font-family: Akrobat;
      font-size: 32px;
      line-height: 40px;
    }

    form {
      & > span{
        color: #334150;
        font-family: Akrobat;
        font-size: 16px;
        line-height: 20px;
        margin: 30px 0;
        display: block;
      }
    }
  }
}



@media screen and (max-width: 900px) {
  .basket-page.container{
    width: 100%;
  }
  .product-block-wrapper{
    .name-product .name-info {
      flex-flow: column wrap;
      justify-content: flex-start;
      align-items: flex-start;
    }
    .name-product .name-info p{
      display: none;
    }
  }
}
