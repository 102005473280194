.compare-offers{
  padding: 50px 0 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .compare:first-of-type{
    margin-right: 30px;
  }
}

.compare{
  min-height: 182px;
  background: #fff;
  padding: 20px 30px;
  width: 50%;
}

.compare-wrapper{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 40px;
  position: relative;
  .label{
    position: absolute;
    top: -25px;
    right: -5px;
  }
  .compare-info{
    display: flex;
    align-items: center;
    .price{
      padding: 2px 5px;
      border: 1px solid #FF5781;
      border-radius: 2px;
      color: #FF5781;
      font-size: 11px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 15px;
    }
    .name{
      color: #0253B3;
      font-size: 14px;
      line-height: 17px;
      margin-right: 10px;
      -webkit-transition: all 0.3s linear;
      -moz-transition: all 0.3s linear;
      -ms-transition: all 0.3s linear;
      -o-transition: all 0.3s linear;
      transition: all 0.3s linear;
      &:hover{
        text-decoration: none;
      }
    }
    .reviews-wrapper{
      display: flex;
      flex-flow: row;
      justify-content: flex-start;
      align-items: flex-end;
      padding: 0;
      .review{
        margin: 0 5px;
        color: #7F8FA4;
        font-family: Akrobat;
        font-size: 12px;
        font-weight: 300;
      }
    }
  }
  .state{
    color: #334150;
    font-family: Akrobat;
    font-size: 12px;
    line-height: 15px;
    margin-right: 35px;
  }
}

.product-name{
  font-size: 16px;
  font-weight: 300;
  display: flex;
  align-items: center;
  .product{
    font-weight: 600;
    color: #334150;
    padding-right: 10px;
  }
  .product-code{
    color: #7F8FA4;
  }
}

.firm-product{
  color: #7F8FA4;
  font-size: 14px;
}

.stock{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  .stock-info{
    display: flex;
    align-items: center;
    >div{
      display: flex;
      align-items: flex-end;
    }
    >div:first-of-type{
      padding-right: 25px;
    }
    span{
      padding-right: 5px;
      color: #7F8FA4;
      font-size: 12px;
      font-weight: 300;
    }
    p{
      color: #334150;
      font-size: 14px;
    }
  }
}

.product-buy{
  display: flex;
  align-items: center;
  .price{
    color: #334150;
    font-size: 23px;
    line-height: 27px;
    padding-right: 25px;
    display: flex;
    p{
      font-weight: 600;
      padding-right: 3px;
    }
  }
}

.btn-buy{
  font-family: "Akrobat", sans-serif;
  color: #FFFFFF;
  font-size: 13px;
  /*font-weight: bold;*/
  height: 36px;
  width: 120px;
  border-radius: 3px;
  background-color: #16D761;
  box-shadow: 0 2px 4px 0 rgba(139,139,139,0.5);
  line-height: 16px;
  cursor: pointer;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  img{
    margin-right: 4px;
  }
  &:hover{
    opacity: 0.7;
  }
}
