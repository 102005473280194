.default_dialog_root .paper_custom_dialog{
    max-width: 900px;
    width: 100%;
    min-width: 0px;
}
.modal-container{
    display: flex;
    flex-flow: column;
    height: 100%;
    width: 100%;
    color: #374453;
    .close{
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-end;
        align-items: flex-end;
        cursor: pointer;
    }
    .main-block{
        display: flex;
        flex-flow: column;
        align-items: flex-start;
        justify-content: stretch;
        h1 {
            font-size: 2em;
            margin-bottom: 20px;
        }
        h2{
            font-size: 1.4em;
            margin-bottom: 20px;
        }
        span.text{
            font-size: 14px;
            margin-bottom: 20px;
            color: darkgray;
        }
        .data-provider{
            display: flex;
            flex-flow: row wrap;
            .loader_wrapper{
              position: relative;
              top: 0;
              bottom: 0;
              right: 0;
              left: 0;
              height: 340px;
              width: 583px;
            }
            .schedule_map_wrapper{
                width: 595px;
                height: 260px;
                position: relative;
                overflow: hidden;
            }
            .schedule{
              margin-right: 25px;
              width: 110px;



            }
        }

    }
}

.modal-container.map{
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
  color: #374453;
  .close{
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-end;
      align-items: flex-end;
      cursor: pointer;
  }
  .main-block{
      display: flex;
      flex-flow: column;
      align-items: flex-start;
      justify-content: stretch;
      h1 {
          color: #334150;
          font-family: Akrobat;
          font-size: 32px;
          line-height: 40px;
          margin-bottom: 20px;
      }
      h2{
        color: #334150;
        font-family: Akrobat;
        font-size: 23px;
        line-height: 29px;
        margin-bottom: 20px;
      }
      span.text{
          color: #7F8FA4;
          font-family: Akrobat;
          font-size: 12px;
          font-weight: 300;
          line-height: 18px;
          margin-bottom: 10px;
      }
      .data-provider{
          display: flex;
          flex-flow: row wrap;
          .loader_wrapper{
            position: relative;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            width: 580px;
            height: 260px;
          }
          .schedule_map_wrapper{
              width: 580px;
              height: 260px;
          }
          .schedule{
            margin-right: 25px;
            width: 110px;
            .block_label{
              color: #7F8FA4;
              font-family: Akrobat;
              font-size: 12px;
              font-weight: 300;
              line-height: 15px;
              margin-bottom: 20px;
            }
            .schedule-time{
              margin: 0;
              .time_day{
                color: #334150;
                font-family: Akrobat;
                font-size: 14px;
                font-weight: 600;
                line-height: 17px;
                margin-right: 15px;
              }
              .time_value{
                color: #334150;
                font-family: Akrobat;
                font-size: 14px;
                line-height: 18px;
              }
            }
            .schedule-time_inactive{
              margin: 0;
              .time_day{
                color: #7F8FA4;
                font-family: Akrobat;
                font-size: 14px;
                font-weight: 600;
                line-height: 17px;
                margin-right: 15px;
              }
              .time_value{
                color: #7F8FA4;
                font-family: Akrobat;
                font-size: 14px;
                line-height: 18px;
              }
            }
          }
      }

  }
}

.modal-container.confirm{
    min-height: 195px;
    .main-block{
        height: 120px;
        margin: 0 auto;
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid #9f9f9fbf;
    }
    .list-button{
        display: flex;
        flex-flow: row wrap;
        margin: 10px 20px;
        *{
            margin: 0 10px;
        }
    }
}
