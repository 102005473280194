.supplier{
  padding: 40px 50px 10px;
  margin-bottom: 30px;
  background: #fff;
  .title-block{
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    p{
      color: #334150;
      font-size: 32px;
      line-height: 40px;
      padding-right: 15px;
    }
    .rating_wrapper > span{
      color: #0253B3;
      font-size: 14px;
      text-decoration: underline;
    }
  }

}

.address-block{
  display: flex;
  flex-flow: column;
  .block_label{
    padding: 10px 0;
    color: #7F8FA4;
    font-size: 12px;
    font-weight: 300;
    line-height: 18px;
  }
}

.info-supplier{
  display: flex;
  align-items: center;
  padding-bottom: 30px;
  .painting{
    min-width: 125px;
    .descriptions{
      color: #7F8FA4;
      font-size: 12px;
      font-weight: 300;
      padding-bottom: 20px;
    }
  }
  .painting-day{
    padding-bottom: 15px;
    display: flex;
    span{
      color: #334150;
      font-size: 14px;
      font-weight: 600;
      line-height: 17px;
      padding-right: 15px;
    }
    p{
      color: #334150;
      font-size: 14px;
      line-height: 18px;
    }
    .Output{
      color: #7F8FA4;
    }
  }
  .painting-day:last-of-type{
    padding-bottom: 0;
  }
}
.address-block {
  .address {
    padding-bottom: 20px;
    color: #334150;
    font-size: 23px;
    cursor: pointer;
    button {
      padding: 5px;
      svg {
        font-size: 18px;
      }
    }
  }
}
