.default_dialog_root {
  .paper_custom_dialog{
    min-width: 840px;
    width: 100%;
  }
  .dialog_close_button {
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 15px;
    background: transparent;
  }

  .title-dialog{
    color: #334150;
    font-size: 32px;
    line-height: 40px;
    padding-bottom: 30px;
  }
  .descriptions{
    color: #334150;
    font-size: 16px;
    line-height: 20px;
  }
  .btn-wrapper{
    padding-top: 30px;
    margin-top: 30px;
    border-top: 1px solid #C2D1D9;
    display: flex;
    width: 100%;
    button, a {
      margin-right: 14px;
    }
  }
}


.dialog-form{
  width: 100%;
}

.dialog-field{
  display: flex;
  .custom_input_wrapper{
    margin-right: 50px;
  }
  .check_field_wrapper{
    margin-right: 30px;
  }
}

.dialog-field-change{
  display: flex;
  flex-flow: column;
  .custom_input_wrapper{
    margin-bottom: 20px;
    position: relative;
    i{
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}

.dialog-field-representation{
  .custom_input_wrapper{
    max-width: 100%;
  }
}

.block-change-info{
  width: 100%;
  .text-info{
    display: flex;
    align-items: center;
    color: #334150;
    font-size: 16px;
    line-height: 20px;
    img{
      margin-right: 7px;
    }
  }
}

.dialog_wrapper {
  width: 100%;
  padding: 40px 50px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
  border-radius: 5px;
  background-color: #FFFFFF;
  .map_wrapper {
    .loader_wrapper {
      min-height: 426px;
    }
  }
}



