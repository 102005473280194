.dashboard-catalog{
  padding-left: 150px;
  padding-bottom: 50px;
  background: #ffffff;
  .title-wrapper{
    padding: 30px 0 20px;
  }
}

.dashboard-tabs{
  .tab-button{
    button{
      color: #7F8FA4;
      font-size: 16px;
      line-height: 20px;
      background: transparent;
      cursor: pointer;
      font-family: "Akrobat", sans-serif;
      margin-right: 30px;
      &:hover{
        color: #334150;
      }
    }
    .active{
      position: relative;
      color: #334150;
      &:before{
        content: "";
        position: absolute;
        left: 0;
        bottom: -9px;
        height: 2px;
        width: 100%;
        background: #0253B3;
      }
    }
  }
}

.catalog{
  max-height: 370px;
  display: flex;
  flex-direction: column;
  padding-top: 50px;
  flex-wrap: wrap;
  align-content: flex-start;
}
.catalog-large{
  max-height: 700px;
}

.catalog-info{
  position: relative;
  padding-left: 15px;
  text-decoration: none;
  width: 16%;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  cursor: pointer;
  &:hover{
    text-decoration: underline;
    opacity: 0.7;
  }
  p{
    padding-bottom: 20px;
    color: #0253B3;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }
  .letter{
    position: absolute;
    top: -10px;
    left: 0;
    opacity: 0.15;
    color: #0253B3;
    font-size: 32px;
    font-weight: 900;
    line-height: 40px;
  }
}

.all-catalog{
  padding-top: 10px;
  .btn_hide{
    svg{
      font-size: 12px;
    }
  }
  a{
    color: #0253B3;
    font-size: 16px;
    line-height: 24px;
    text-decoration: none;
    &:hover{
      text-decoration: underline;
    }
    span {
      transform: rotate(90deg);
      display: inline-block;
    }
  }
}

.catalog_banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 4px 16px 0 rgba(0,0,0,0.15);
  background-color: #FFFFFF;
  height: 359px;
  padding: 60px 50px 65px;
  h4 {
    color: #334150;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    font-weight: 400;
  }
  p {
    color: #334150;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
  }
}
